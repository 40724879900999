import React from "react";
import Title from "components/Title";
import AddUser from "./AddNewMessage";

const TableHeader = () => {
  return (
    <div
      className="gx-d-flex gx-align-items-center gx-justify-content-between gx-mb-3"
      style={{ flexWrap: "wrap" }}
    >
      <Title title="directNiotification.title" />
      <AddUser />
    </div>
  );
};

export default TableHeader;
