import React from "react";
import Layout from "components/Layout";
import Widget from "components/Widget";
import UserList from "components/Table/List";
import Action from "components/Table/Actions";
import EditUser from "./components/EditUser";
import ResetPassword from "./components/ResetPassword";
import TableHeader from "./components/TableHeader";
import dummy from "../../assets/images/dummyImage.jpeg";
import { intlPlaceholder } from "util/intlPlaceholder";
import { renderStatus } from "utils/commonUseFunction";
import DeleteConfirm from "../../components/Modal/DeleteConfirm";
import { useDispatch } from "react-redux";
import { DataRequestAction } from "../../redux/actions/CommonHttp";
import ShowModal from "components/Modal/ShowModal";
import moment from "moment";
import { Image } from "antd";
import ViewOtpModal from "./components/ViewOtpModal";
const Index = (record) => {
  const dispatch = useDispatch();
  const changeStatus = (id, status) => {
    dispatch(
      DataRequestAction(
        "Put",
        "editUser",
        "EditRecord",
        {
          _id: id,
          status: status,
        },
        "StartSpinner",
        "",
        "userList",
        `${status} Successfully`,
        ""
      )
    );
  };

  const renderActions = (record) => {
    const status = record.status === "active" ? "block" : "active";
    return (
      <div style={{ width: "120px" }} className="gx-d-flex gx-flex-column">
        <span
          className="gx-pointer"
          onClick={() => changeStatus(record._id, status)}
        >
          {record.status === "active" ? "Block" : "Active"}
        </span>

        <ShowModal
          modalName="EDIT_USER_MODAL"
          buttonName="Edit"
          record={record}
        />

        {record.verifyPhoneNumber === false && (
          <ShowModal
            modalName="View_Otp_Modal"
            buttonName="View OTP"
            record={record}
          />
        )}
        {record.status === "active" && (
          <ShowModal
            modalName="REST_PASSWORD_MODAL"
            buttonName="Change Password"
            record={record}
          />
        )}

        <hr className="gx-mt-2 gx-mb-1" />
        <DeleteConfirm
          type="deleteUser"
          apiRequest="DeleteRecord"
          recordName="userList"
          title="Are you sure you want to delete this user"
          content="You can't undo this action"
          selectedItem={record}
          Icon="Delete"
        />
      </div>
    );
  };

  const columns = [
    {
      title: intlPlaceholder("user.id"),
      render: (record) => <span>{record.userId}</span>,
      key: "userId",
      width: "100px",
    },
    {
      title: intlPlaceholder("user.username"),
      render: (record) => <span>{record.username || "-----"}</span>,
      key: "username",
      width: "250px",
    },
    {
      title: intlPlaceholder("user.fullName"),
      render: (record) => (
        <div className="gx-d-flex gx-align-items-center" style={{ gap: "5px" }}>
          <Image
            src={record.profileImageUrl || dummy}
            alt=""
            width="40px"
            height="40px"
            style={{ borderRadius: "10px" }}
          />
          <span className="gx-text-capitalize">{record.fullName || "---"}</span>
        </div>
      ),
      key: "fullName",
      width: "250px",
    },
    {
      title: intlPlaceholder("user.email"),
      render: (record) => <span>{record.email || "---"}</span>,
      key: "email",
      width: "200px",
    },
    {
      title: intlPlaceholder("user.phoneNumber"),
      render: (record) => <span>{record.phoneNumber || "---"}</span>,
      key: "phoneNumber",
      width: "200px",
    },
    {
      title: intlPlaceholder("user.profileViews"),
      render: (record) => <span>{record.profileViews || "---"}</span>,
      key: "profileViews",
      width: "200px",
    },
    {
      title: intlPlaceholder("user.createdBy"),
      render: (record) => (
        <span>{moment(record.createdAt).format("DD-MMM-YYYY") || "---"}</span>
      ),
      key: "createdBy",
      width: "150px",
    },
    {
      title: intlPlaceholder("user.status"),
      render: (record) => <span>{renderStatus(record.status)}</span>,
      key: "status",
    },
    {
      title: "",
      render: (record) => <Action>{renderActions(record)}</Action>,
      key: "action",
      width: "50px",
    },
  ];

  return (
    <Layout>
      <Widget>
        <TableHeader />
        <UserList
          apiName="getUser"
          recordName="userList"
          columns={columns}
          scroll={true}
          pagination={true}
        />
      </Widget>
      <EditUser />
      <ResetPassword />
      <ViewOtpModal />
    </Layout>
  );
};

export default Index;
