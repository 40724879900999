import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  TAB_SIZE,
} from "constants/ThemeSetting";

const SidebarLogo = () => {
  const { width } = useSelector(({ settings }) => settings);
  let navStyle = useSelector(({ settings }) => settings.navStyle);
  if (width < TAB_SIZE && navStyle === NAV_STYLE_FIXED) {
    navStyle = NAV_STYLE_DRAWER;
  }
  return (
    <div className="gx-layout-sider-header gx-justify-content-center">
      <Link to="/" className="gx-site-logo ">
        <div className="gx-font-sans-bold gx-mt-2" style={{ fontSize: "28px" }}>
          <span style={{ color: "#722F38" }}>Pet</span>{" "}
          <span style={{ color: "#722F38" }}>Pal</span>
        </div>
      </Link>

      <Link to="/" className="gx-site-logo-small ">
        <div className="gx-font-sans-bold gx-mt-2" style={{ fontSize: "28px" }}>
          <span style={{ color: "#722F38" }}>Pet</span>{" "}
        </div>
      </Link>
    </div>
  );
};

export default SidebarLogo;
