import React from "react";
import { Tag } from "antd";
import moment from "moment";

export const renderSubstring = (data, length, subtract) => {
  return (
    <span>{data.length > length ? data.substring(0, subtract) + "..." : data}</span>
  );
};
export const renderDate = (date, format = "MMMM Do YYYY") => {
  return moment(date).format(format);
};
export const renderStatus = (status) => {
  if (status === "active") {
    return <Tag color="green">Active</Tag>;
  }
  if (status === "activate") {
    return <Tag color="green">Active</Tag>;
  }
  if (status === "pending") {
    return <Tag color="blue">Pending</Tag>;
  }
  if (status === "block") {
    return <Tag color="red">Block</Tag>;
  }
  if (status === "deActivate") {
    return <Tag color="red">De-Active</Tag>;
  }
  if (status === "deleted") {
    return <Tag color="red">Deleted</Tag>;
  }
};
