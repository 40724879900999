import React from "react";
import { Form, Input, Image, Row } from "antd";
import NewsAttachments from "../../../components/AvatarUploader/CustomUploader";
import { intlPlaceholder } from "util/intlPlaceholder";
import ReactQuil from "../../../components/ReactQuil";
const { TextArea } = Input;
const NewsForm = ({ initialValues, formType, myAssigned, updateAssigned }) => {
  const read = formType === "view" ? true : false;
  const renterImage = () => {
    if (
      initialValues &&
      initialValues.fileUrl &&
      initialValues.fileUrl.length > 0
    ) {
      return initialValues.fileUrl.map((items) => (
        <div className="gx-mt-1">
          <Image
            style={{ borderRadius: "10px" }}
            src={items}
            alt=""
            width="112px"
            height="100px"
          />
        </div>
      ));
    } else {
      return (
        <h3 style={{ color: "#7D7D7D", margin: "0px auto" }}>
          Images Not Found
        </h3>
      );
    }
  };

  return (
    <div className="gx-px-3">
      <Form.Item
        name="title"
        label={intlPlaceholder("news.title")}
        rules={[
          {
            required: true,
            message: intlPlaceholder("news.title_message"),
          },
        ]}
      >
        <Input readOnly={read} />
      </Form.Item>
      <ReactQuil
        readOnly={read}
        Form={Form}
        name="description"
        labelName={"Descriptions"}
      />
      <Form.Item
        name="shortDescription"
        label="Short Description"
        rules={[
          {
            required: true,
            message: intlPlaceholder("news.description_message"),
          },
        ]}
      >
        <TextArea rows={5} readOnly={read} />
      </Form.Item>
      {formType !== "view" && (
        <Form.Item label="Attachment">
          <NewsAttachments
            updateAssigned={updateAssigned}
            myAssigned={myAssigned}
          />
        </Form.Item>
      )}
      {formType === "view" && (
        <Form.Item label="Attachment">
          <Row
            className="gx-align-items-center gx-w-100"
            style={{
              height: "124px",
              background: "#F7F8FA",
              borderRadius: "10px",
              overflowY: "hidden",
              overflowX: "auto",
              gap: "12px",
            }}
          >
            {renterImage()}
          </Row>
        </Form.Item>
      )}
    </div>
  );
};

export default NewsForm;
