import React from "react";
import Widget from "../../Widget";
import AuthLeftSide from "../AuthLeftSide/AuthLeftSide";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import { useDispatch, useSelector } from "react-redux";
import {
  DataRequestAction,
  setInitUrl,
} from "../../../redux/actions/CommonHttp";
import { Typography, Form, Input, Button } from "antd";
import { useHistory } from "react-router-dom";

const { Title } = Typography;
const Index = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const authProps = useSelector((state) => state.auth);
  const { loginSuccess, loginStart } = authProps;
  const onFinish = (values) => {
    values.email = values.email.toLowerCase();
    dispatch(
      DataRequestAction(
        "POST",
        "login",
        "Login",
        values,
        "LoginSpinner",
        "",
        "",
        "",
        "Some error occur try later"
      )
    );
  };
  if (loginSuccess) {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    dispatch({
      type: "update_auth",
    });
    if (userInfo.role === "superAdmin") {
      dispatch(setInitUrl("/dashboard"));
      history.push("/dashboard");
    }
  }
  return (
    <AuthLeftSide>
      <Widget className="login-form gx-mr-md-4 gx-ml-md-0 gx-ml-3">
        <div
          className="gx-h-100 gx-d-flex gx-flex-column gx-align-items-center gx-justify-content-center"
          style={{ gap: "8%" }}
        >
          <div className="gx-text-center">
            <Title
              className="gx-font-sans-bold gx-text-black gx-mb-0"
              level={2}
            >
              {intlPlaceholder("logIn.pageTitle")}
            </Title>
            <Title className="gx-font-sans-regular gx-mt-0" level={5}>
              {intlPlaceholder("logIn.content")}
            </Title>
          </div>
          <Form
            name="normal_login"
            className="login-form-inputs"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            layout="vertical"
          >
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  message: intlPlaceholder("Enter is required"),
                },
                {
                  type: "email",
                  message: intlPlaceholder("Enter Valid Email"),
                },
              ]}
            >
              <Input placeholder={intlPlaceholder("Enter Your Email")} />
            </Form.Item>
            <Form.Item
              name="password"
              label="Password"
              className="gx-mb-1"
              rules={[
                {
                  required: true,
                  message: intlPlaceholder("Password is required"),
                },
              ]}
            >
              <Input.Password
                className="gx-w-100"
                placeholder={intlPlaceholder("Enter Your Password")}
              />
            </Form.Item>
            <div className="gx-d-flex gx-mb-3  gx-justify-content-end">
              <span
                className="gx-link gx-pointer gx-font-sans-bold gx-font-sans-regular gx-d-flex gx-flex-end gx-pointer gx-mt-2 gx-pt-1 "
                onClick={() => history.push("/forgetPassword")}
                style={{ color: "#4C5755" }}
              >
                Forgot Your password?
              </span>
            </div>
            <Button
              htmlType="submit"
              loading={loginStart}
              type="primary"
              disabled={loginStart}
              className="gx-w-100 gx-font-sans-bold gradient-button"
            >
              {intlPlaceholder("logIn.btn")}
            </Button>
          </Form>
        </div>
      </Widget>
    </AuthLeftSide>
  );
};

export default Index;
