import React from "react";
import { Route, Switch } from "react-router-dom";
import Dashboard from "./Dashboard";
import User from "./User";
import Search from "./Search";
import FindPet from "./FindPet";
import AdoptPet from "./AdoptPet";
import SalePet from "./Sale";
import PetMainCategory from "./PetMainCategory";
import Age from "./Age";
import Gender from "./Gender";
import Color from "./Color";
import News from "./News";
import Banner from "./Banner";
import Setting from "./Setting";
import UserDetail from "./Search/components/UserDetail";
import BroadcastMessage from "./BroadcastMessage";
import DirectNiotification from "./DirectNiotification";
import ShowViewPostDetail from "./ViewUserClickonPost/ShowViewPostDetail";

const App = ({ match }) => (
  <Switch>
    <Route path={`${match.url}dashboard`} component={Dashboard} />
    <Route path={`${match.url}user`} component={User} />
    <Route path={`${match.url}search`} component={Search} />
    <Route path={`${match.url}userDetail`} component={UserDetail} />
    <Route
      path={`${match.url}directNiotification`}
      component={DirectNiotification}
    />
    <Route path={`${match.url}viewUsers`} component={ShowViewPostDetail} />
    <Route path={`${match.url}post/findPet`} component={FindPet} />
    <Route path={`${match.url}post/adoptPet`} component={AdoptPet} />
    <Route path={`${match.url}post/salePet`} component={SalePet} />
    <Route
      path={`${match.url}PostSetting/category`}
      component={PetMainCategory}
    />
    <Route path={`${match.url}PostSetting/age`} component={Age} />
    <Route path={`${match.url}PostSetting/color`} component={Color} />
    <Route path={`${match.url}PostSetting/gender`} component={Gender} />
    <Route path={`${match.url}news`} component={News} />
    <Route path={`${match.url}broadcastMessage`} component={BroadcastMessage} />
    <Route path={`${match.url}banner`} component={Banner} />
    <Route path={`${match.url}setting`} component={Setting} />
  </Switch>
);

export default App;
