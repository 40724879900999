import React from "react";
import { Form, Input, Row, Col, Button } from "antd";
import { DataRequestAction } from "redux/actions/CommonHttp";
import { useDispatch, useSelector } from "react-redux";
import SimpleModal from "components/Modal/SimpleModal";

const ResetPassword = () => {
  const curdProps = useSelector((state) => state.CurdR);
  const [form] = Form.useForm();
  const { REST_PASSWORD_MODAL, initialValues, Loader } = curdProps;
  if (REST_PASSWORD_MODAL) {
    form.resetFields();
  }
  const renderClose = () => {
    dispatch({
      type: "Hide_Modal",
      payload: null,
      ModalName: "REST_PASSWORD_MODAL",
    });
  };
  const dispatch = useDispatch();
  const onFinish = (values) => {
    values._id = initialValues && initialValues._id;
    dispatch(
      DataRequestAction(
        "PUT",
        "changeUserPassword",
        "EditRecord",
        values,
        "StartSpinner",
        "REST_PASSWORD_MODAL",
        "userList"
      )
    );
  };

  return (
    <SimpleModal
      title="Change Password"
      modalFor="REST_PASSWORD_MODAL"
      visible={REST_PASSWORD_MODAL}
    >
      <Form layout="vertical" name="reassignMo" onFinish={onFinish} form={form}>
        <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gx-px-0">
            <Form.Item
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                  message: "Password is required",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
          <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gx-px-0">
            <Form.Item
              name="confirmPassword"
              label="Confirm Password"
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                  message: "Confirm password is required",
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject("Password is not match");
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gx-px-0">
            <div className="gx-flex gx-flex-row  gx-justify-content-end ">
              <Form.Item className="gx-mb-0">
                <div className="gx-d-flex">
                  <Button
                    className="gx-pt-0 gx-mb-0"
                    htmlType="button"
                    onClick={() => renderClose()}
                  >
                    Cancel
                  </Button>

                  <Button
                    type="primary"
                    className="gx-pt-0 gx-mb-0"
                    htmlType="submit"
                    disabled={Loader}
                    loading={Loader}
                  >
                    Update
                  </Button>
                </div>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>
    </SimpleModal>
  );
};

export default ResetPassword;
