import React from "react";
import Widget from "../../Widget";
import AuthLeftSide from "../AuthLeftSide/AuthLeftSide";
import { DataRequestAction } from "redux/actions/CommonHttp";
import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Form, Input, Button } from "antd";
const { Title } = Typography;
const Index = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const authProps = useSelector((state) => state.auth);
  const { setSuccess, setSpinner } = authProps;
  const Submit = (values) => {
    if (params && params.query !== "") {
      dispatch(
        DataRequestAction(
          "POST",
          "setNewPassword",
          "setNewPassword",
          { ...values, token: params.query },
          "setSpinner",
          "",
          ""
        )
      );
    }
  };
  if (setSuccess) {
    dispatch({ type: "update_auth" });
    history.push("/login");
  }

  return (
    <AuthLeftSide>
      <Widget className="login-form  gx-mr-md-4 gx-ml-md-0 gx-ml-3">
        <div
          className="gx-h-100 gx-d-flex gx-flex-column gx-align-items-center gx-justify-content-center"
          style={{ gap: "8%" }}
        >
          <div className="gx-text-center">
            <Title
              className="gx-font-sans-bold gx-text-black gx-mb-0"
              level={2}
            >
              Set New Password?
            </Title>
            <Title className="gx-font-sans-regular gx-mt-0" level={5}>
              Enter your New Password.
            </Title>
          </div>
          <Form
            layout="vertical"
            className="gx-py-3 gx-d-flex gx-flex-column login-form-inputs"
            onFinish={Submit}
          >
            <Form.Item
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                  message: "Password is required",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              name="confirmPassword"
              label="Confirm Password"
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                  message: "Confirm password is required",
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject("Password is not match");
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Button
              htmlType="submit"
              type="primary"
              className="gx-w-100 gx-mt-4 gradient-button"
              loading={setSpinner}
              disabled={setSpinner}
            >
              Save
            </Button>
          </Form>
        </div>
      </Widget>
    </AuthLeftSide>
  );
};

export default Index;
