import React from "react";
import FormModal from "components/Modal/FormModal";
import ColorForm from "./ColorForm";
import { intlPlaceholder } from "util/intlPlaceholder";
import { useSelector } from "react-redux";
import { Form } from "antd";
const AddColor = () => {
  const [form] = Form.useForm();
  const { ADD_Color_MODAL } = useSelector((state) => state.CurdR);
  return (
    <FormModal
      addButtonName={intlPlaceholder("color.addColour")}
      modalTitle={intlPlaceholder("color.colourTitle")}
      addApiName="addColor"
      ModalName="ADD_Color_MODAL"
      visible={ADD_Color_MODAL}
      width={500}
      recordName={"colorList"}
      form={form}
    >
      <ColorForm />
    </FormModal>
  );
};

export default AddColor;
