import { Col, DatePicker, Form, Input, Row } from "antd";
import React from "react";
import AvatarUploaderSquare from "components/AvatarUploaderSquare";
import moment from "moment/moment";
const { TextArea } = Input;
const BannerForm = ({ setPhotoState, photoList, initialValues, formType }) => {
  const renderInputs = () => {
    return (
      <Row className="gx-flex-row">
        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            name="startDate"
            label="Start Date"
            rules={[
              {
                required: true,
                message: "Start Date is required",
              },
            ]}
          >
            <DatePicker
              format="MM-DD-YYYY"
              style={{ width: "270px" }}
              disabledDate={(current) =>
                current.isBefore(moment().subtract(1, "day"))
              }
            />
          </Form.Item>
        </Col>
        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            name="endDate"
            label="End Date"
            rules={[
              {
                required: true,
                message: "End Date is required",
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("startDate") <= value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    "The end date is equal to or greater than to start date"
                  );
                },
              }),
            ]}
          >
            <DatePicker
              format="MM-DD-YYYY"
              style={{ width: "270px" }}
              disabledDate={(current) => {
                current.isBefore(moment().subtract(1, "day"));
              }}
            />
          </Form.Item>
        </Col>
      </Row>
    );
  };
  return (
    <>
      <Row className="gx-flex-row">
        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            name="title"
            label={"Title"}
            rules={[
              {
                required: true,
                message: "title is required",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            name="orderNo"
            label={"Order Number"}
            rules={[
              {
                required: true,
                message: "field is required",
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
        </Col>

        <Col xl={24} lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            name="hyperLink"
            label={"Hyper Link"}
            rules={[
              {
                required: true,
                message: "link is required",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      {formType !== "add" ? (
        <>
          {initialValues && initialValues.type === "custom" && renderInputs()}
        </>
      ) : (
        renderInputs()
      )}

      <Row>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Form.Item name="description" label="Description">
            <TextArea rows={5} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gx-pt-2">
          <span className="gx-font-sans-bold ">Upload Image (382 X 130)</span>
        </Col>
      </Row>
      <Row>
        <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gx-pt-2">
          <AvatarUploaderSquare
            setPhotoState={setPhotoState}
            photoList={photoList}
          />
        </Col>
      </Row>
    </>
  );
};

export default BannerForm;
