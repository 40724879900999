import React from "react";
import Title from "components/Title";
import PostFilters from "../../../components/PostFilters/PostFilters";
const TableHeader = () => {
  return (
    <div
      className="gx-d-flex gx-align-items-center gx-justify-content-between gx-mb-3"
      style={{ flexWrap: "wrap" }}
    >
      <Title title="sale.title" className='gx-pb-3'  />
      <PostFilters type="sale" recordName='saleList' />
    </div>
  );
};

export default TableHeader;
