import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, message, Row, Col } from "antd";

const Index = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const notificationProps = useSelector((state) => state.Notification);
  const { SendNotificationSuccess, SendNotificationError } = notificationProps;

  if (SendNotificationSuccess) {
    form.resetFields();
    message.success("Send Message Successfully");
    dispatch({ type: "Notification_RESET" });
  }
  if (SendNotificationError) {
    message.error("Some Error Occur Try later");
    dispatch({ type: "Notification_RESET" });
  }
  return (
    <Row>
      <Col xl={24} lg={14} md={14} sm={24} xs={24}>
        <Form.Item
          label="Title"
          name="title"
          rules={[{ required: true, message: "Title is required" }]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <Form.Item
          label="Message"
          name="description"
          rules={[{ required: true, message: "Description is required" }]}
        >
          <Input.TextArea rows={5} />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default Index;
