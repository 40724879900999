import React from "react";
import FormModal from "components/Modal/FormModal";
import BroadcastForm from "./BroadcastForm";
import { useSelector } from "react-redux";
import { Form } from "antd";
const AddBroadcast = () => {
  const [form] = Form.useForm();
  const { ADD_Broadcast_MODAL } = useSelector((state) => state.CurdR);
  return (
    <FormModal
      addButtonName={("Add Broadcast")}
      modalTitle={("Add New Broadcast")}
      addApiName="addBroadCast"
      submitButtonText="Send"
      addApiRequest="AddRecord"
      ModalName="ADD_Broadcast_MODAL"
      visible={ADD_Broadcast_MODAL}
      width={500}
      recordName="broadcastList"
      form={form}
    >
      <BroadcastForm />
    </FormModal>
  );
};

export default AddBroadcast;
