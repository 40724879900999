import React from "react";
import Layout from "components/Layout";
import Widget from "components/Widget";
import UserList from "components/Table/List";
import TableHeader from "./ShowViewDetailHeader";
import dummy from "../../assets/images/dummyImage.jpeg";
import { intlPlaceholder } from "util/intlPlaceholder";
import { Image } from "antd";
import { useLocation } from "react-router-dom";
const ShowViewPostDetail = () => {
  const params = useLocation();
  const { state } = params;
  const columns = [
    {
      title: intlPlaceholder("user.id"),
      render: (record) => <span>{record.userId}</span>,
      key: "userId",
      width: "100px",
    },
    {
      title: intlPlaceholder("user.username"),
      render: (record) => <span>{record.username || "-----"}</span>,
      key: "username",
      width: "250px",
    },
    {
      title: intlPlaceholder("user.fullName"),
      render: (record) => (
        <div className="gx-d-flex gx-align-items-center" style={{ gap: "5px" }}>
          <Image
            src={record.profileImageUrl || dummy}
            alt=""
            width="40px"
            height="40px"
            style={{ borderRadius: "10px" }}
          />
          <span className="gx-text-capitalize">{record.fullName || "---"}</span>
        </div>
      ),
      key: "fullName",
      width: "250px",
    },
    {
      title: intlPlaceholder("user.email"),
      render: (record) => <span>{record.email || "---"}</span>,
      key: "email",
      width: "200px",
    },
    {
      title: intlPlaceholder("user.phoneNumber"),
      render: (record) => <span>{record.phoneNumber || "---"}</span>,
      key: "phoneNumber",
      width: "200px",
    },
    {
      title: "View Count",
      render: (record) => <span>{record.count || "---"}</span>,
      key: "phoneNumber",
    },
  ];

  return (
    <Layout>
      <Widget>
        <TableHeader state={state} />
        <UserList
          apiName="getPostCountUserDetails"
          recordName="getPostCountUserDetailsList"
          customQuery={{ _id: state && state._id }}
          columns={columns}
          scroll={true}
          pagination={true}
        />
      </Widget>
    </Layout>
  );
};

export default ShowViewPostDetail;
