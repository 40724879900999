const Message = {
  Login_Success: "Welcome Back ",
  Logout: "Thanks For using pet pal",
  Forgot_Password_Desc:
    "Don't worry. Recovering the password is easy.Just tell us the email you have registered with collab care.",

  record_updated_success: "Record Updated Successfully",
  record_updated_error: "Some Error Occur While updating the Record",
};

export default Message;
