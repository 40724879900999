import React from "react";
import MenuList from "./Menu";
import IntlMessages from "../../../util/IntlMessages";
import SidebarLogo from "./SidebarLogo";
import { Menu } from "antd";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import SidebarImag from "../../../assets/image/petpal logo-scrach.png";
const SubMenu = Menu.SubMenu;
const SidebarContent = () => {
  const location = useLocation();
  const { pathname } = location;
  const logInUser = useSelector((state) => state.auth.authUser);
  const role = logInUser && logInUser.role;
  const MenuData = MenuList[role];
  const selectedKeys = pathname.substr(1);
  let keyArray = selectedKeys.split("/");
  let defaultOpenKeys = [keyArray[0]];
  if (selectedKeys.split("/").length === 3) {
    defaultOpenKeys = [keyArray[0], keyArray[1]];
  }

  const singleMenuItem = (Item) => {
    return (
      <Menu.Item key={Item.url}>
        <Link
          to={{ pathname: Item.url, state: { key: Item.key } }}
          key={Item.url + "key"}
        >
          <i
            className={`icon icon-${Item.icon}`}
            style={{
              top: "2px",
              position: "relative",
            }}
          />
          <span>
            {<IntlMessages id={Item.title} />}
            {Item.badges ? console.log("=====") : ""}
          </span>
        </Link>
      </Menu.Item>
    );
  };

  const renderSubSubMenu = (Item) => {
    return (
      <SubMenu
        popupClassName="nested-sub-menu"
        key={Item.key}
        title={
          <span>
            <i className={`icon icon-${Item.icon}`} />
            <span> {<IntlMessages id={Item.title} />} </span>
          </span>
        }
      >
        {Item.subMenu.map((x) => {
          return singleMenuItem(x);
        })}
      </SubMenu>
    );
  };

  const renderSubMenu = (Item) => {
    return (
      <SubMenu
        className="classOnHover"
        key={Item.key}
        title={
          <span>
            <i className={`icon icon-${Item.icon}`} />
            <span> {<IntlMessages id={Item.title} />}</span>
          </span>
        }
      >
        {Item.subMenu.map((x) => {
          if (x.subMenu) {
            return renderSubSubMenu(x);
          } else {
            return singleMenuItem(x);
          }
        })}
      </SubMenu>
    );
  };

  const renderMenu = () => {
    return MenuData.map((Item) => {
      if (Item.subMenu) {
        return renderSubMenu(Item);
      } else {
        return singleMenuItem(Item);
      }
    });
  };

  return (
    <>
      <div className="gx-sidebar-content">
        <div className="gx-layout-sider-scrollbar">
          <SidebarLogo />
          <div className="gx-d-flex gx-justify-content-center gx-w-100 gx-my-2">
            <img
              src={SidebarImag}
              alt=""
              width="45%"
              height='80%'
              style={{ borderRadius: "50px" }}
            />
          </div>
          <Menu
            style={{ gap: "12px" }}
            className="gx-d-flex gx-flex-column"
            defaultOpenKeys={defaultOpenKeys}
            selectedKeys={["/" + selectedKeys]}
            theme="dark"
            mode="inline"
          >
            {renderMenu()}
          </Menu>
        </div>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};
export default SidebarContent;
