const INIT_STATE = {
  notificationSpin: false,
  SendNotificationError: false,
  SendNotificationSuccess: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "SendNotification_SUCCESS": {
      return {
        ...state,
        SendNotificationSuccess: true,
        notificationSpin: false,
      };
    }
    case "SendNotification_FAILURE": {
      return {
        ...state,
        SendNotificationError: true,
        notificationSpin: false,
      };
    }
    case "notificationSpin_START": {
      return {
        ...state,
        notificationSpin: true,
      };
    }
    case "Notification_RESET": {
      return {
        ...state,
        notificationSpin: false,
        SendNotificationError: false,
        SendNotificationSuccess: false,
      };
    }
    default:
      return state;
  }
};
