import React from "react";
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const Index = ({ data, title, desc, width, height, showYAxis, axisY }) => {
  return (
    <ResponsiveContainer width={width} height={height}>
      <AreaChart data={data} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
        <Tooltip
          labelFormatter={function (value) {
            const dataForValue = data.filter((Item) => Item.name === value);

            return (
              <div>
                <p
                  className="gx-font-sans-regular"
                  style={{ color: "#50A0E1", fontSize: "12px" }}
                >
                  Total Find : {dataForValue[0].TotalFind || 0}
                </p>
                <p
                  className="gx-font-sans-regular"
                  style={{ color: "#50A0E1", fontSize: "12px" }}
                >
                  Total Adopt : {dataForValue[0].totalAdopt || 0}
                </p>
                <p
                  className="gx-font-sans-regular"
                  style={{ color: "#50A0E1", fontSize: "12px" }}
                >
                  Total Sale : {dataForValue[0].totalSale || 0}
                </p>
              </div>
            );
          }}
        />
        <XAxis dataKey={"name"} />
        {showYAxis && <YAxis />}
        <defs>
          <linearGradient id="color15" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#FDCCB8" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#ffffff" stopOpacity={0.8} />
          </linearGradient>
        </defs>
        <Area
          dataKey={axisY}
          strokeWidth={2}
          stackId="2"
          stroke="#F93330"
          fill="url(#color15)"
          fillOpacity={1}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default Index;
