import React from "react";
import Title from "components/Title";
import SearchBox from "components/Filters/SearchBox";
import SearchByStatus from "components/Filters/SelectFilter";
import { intlPlaceholder } from "util/intlPlaceholder";
import AddBanner from "./AddBanner";
import DateRangPicker from "../../../components/Filters/DateRangPicker";
const TableHeader = () => {
  return (
    <div
      className="gx-d-flex gx-align-items-center gx-justify-content-between gx-mb-3"
      style={{ flexWrap: "wrap" }}
    >
      <Title title="banner.title" />

      <div
        className="gx-d-flex gx-align-items-center"
        style={{ flexWrap: "wrap", gap: "10px" }}
      >
        <DateRangPicker
          customQuery={{ query: "date" }}
          apiName="getBanner"
          recordName="bannerList"
          requestType="FetchRecord"
          width="230px"
        />
        <SearchBox
          apiName="getBanner"
          requestType="FetchRecord"
          recordName="bannerList"
          placeholder="ID / Profile"
          customQuery=""
          width="180px"
        />
        <SearchByStatus
          apiName="getBanner"
          recordName="bannerList"
          placeholder={intlPlaceholder("user.searchByStatus_Placeholder")}
          searchKey="status"
          width={"150px"}
          isShowingAll={true}
          options={[
            { status: "activate", label: "Active" },
            { status: "deActivate", label: "De-Active" },
          ]}
          ItemValue="status"
          label="label"
        />
        <AddBanner />
      </div>
    </div>
  );
};

export default TableHeader;
