import React from "react";
import Title from "components/Title";
import SearchBox from "components/Filters/SearchBox";
import AddPetMainCategory from "./AddPetMainCategory";
const TableHeader = () => {
  return (
    <div
      className="gx-d-flex gx-align-items-center gx-justify-content-between gx-mb-3"
      style={{ flexWrap: "wrap" }}
    >
      <Title title="petMainCategory.title" />
      <div
        className="gx-d-flex gx-align-items-center"
        style={{ flexWrap: "wrap", gap: "10px" }}
      >
        <SearchBox
          apiName="getCategory"
          requestType="FetchRecord"
          recordName="petMainCategoryList"
          placeholder="ID / Pet Name"
          width="180px"
        />
        <AddPetMainCategory />
      </div>
    </div>
  );
};

export default TableHeader;
