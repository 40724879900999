import React from "react";
import Title from "components/Title";
import SearchBox from "components/Filters/SearchBox";
import SearchByStatus from "components/Filters/SelectFilter";
import AddUser from "./AddUser";
import { intlPlaceholder } from "util/intlPlaceholder";
const TableHeader = () => {
  return (
    <div
      className="gx-d-flex gx-align-items-center gx-justify-content-between gx-mb-3"
      style={{ flexWrap: "wrap" }}
    >
      <Title title="user.title" />

      <div
        className="gx-d-flex gx-align-items-center"
        style={{ flexWrap: "wrap", gap: "10px" }}
      >
        <SearchBox
          apiName="getUser"
          requestType="FetchRecord"
          recordName="userList"
          placeholder="ID / Name / Email"
          customQuery=""
          width="180px"
        />
        <SearchByStatus
          apiName="getUser"
          recordName="userList"
          placeholder={intlPlaceholder("user.searchByStatus_Placeholder")}
          searchKey="status"
          width={"150px"}
          isShowingAll={true}
          options={[
            { status: "active", label: "Active" },
            { status: "block", label: "Block" },
          ]}
          ItemValue="status"
          label="label"
        />
        <AddUser />
      </div>
    </div>
  );
};

export default TableHeader;
