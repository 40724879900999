import { Form, Image, Input, Select, Button, Row, Col } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGetAction } from "../../../redux/actions/CommonHttp";
import EditAttachments from "../../../components/AvatarUploader/CustomUploader";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
const { TextArea } = Input;
const { Option } = Select;
const Forms = ({ postImages, formType = "view", setPostImages }) => {
  const dispatch = useDispatch();
  const categoryProps = useSelector((state) => state.CurdR);
  const { getCategoryList, selectColorList, selectAgeList, selectGenderList } =
    categoryProps;
  const renterImage = () => {
    if (postImages && postImages.length > 0) {
      return postImages.map((items) => (
        <div className="gx-mt-1">
          <Image
            style={{ borderRadius: "10px" }}
            src={items}
            alt=""
            width="112px"
            height="100px"
          />
        </div>
      ));
    } else {
      return (
        <h3 style={{ color: "#7D7D7D", margin: "0px auto" }}>
          Images Not Found
        </h3>
      );
    }
  };
  const renderCategory = () => {
    dispatch(
      DataGetAction(
        "getCategory",
        "FetchRecord",
        { query: "all" },
        "StartSpinner",
        "",
        "getCategoryList",
        ""
      )
    );
    dispatch(
      DataGetAction(
        "getColor",
        "FetchRecord",
        { query: "all" },
        "StartSpinner",
        "",
        "selectColorList",
        ""
      )
    );
    dispatch(
      DataGetAction(
        "getAge",
        "FetchRecord",
        { query: "all" },
        "StartSpinner",
        "",
        "selectAgeList",
        ""
      )
    );
    dispatch(
      DataGetAction(
        "getGender",
        "FetchRecord",
        { query: "all" },
        "StartSpinner",
        "",
        "selectGenderList",
        ""
      )
    );
  };

  useEffect(renderCategory, []);
  return (
    <div className="gx-px-3">
      <Form.Item
        name="postType"
        label="Post Type"
        rules={[{ required: true, message: "Post Type is required" }]}
      >
        <Select className="gx-text-capitalize" disabled={formType === "view"}>
          <Option value="find">Find Pet</Option>
          <Option value="adopt">Adopt Pet</Option>
          <Option value="sale">Sale Pet</Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="title"
        label="Title"
        rules={[{ required: true, message: "Title is required" }]}
      >
        <Input className="gx-text-capitalize" disabled={formType === "view"} />
      </Form.Item>
      <Form.Item
        name="categoryId"
        label="Category"
        rules={[{ required: true, message: "Category is required" }]}
      >
        <Select className="gx-text-capitalize" disabled={formType === "view"}>
          {getCategoryList &&
            getCategoryList.map((item) => (
              <Option value={item._id}>{item.name}</Option>
            ))}
        </Select>
      </Form.Item>

      <Row style={{ flexDirection: "row" }}>
        <Col
          xl={12}
          lg={12}
          md={12}
          sm={24}
          xs={24}
          className="gx-pl-0 gx-pe-md-1"
        >
          <Form.Item
            name="ageId"
            label="Age"
            rules={[{ required: true, message: "Age is required" }]}
          >
            <Select
              className="gx-text-capitalize"
              placeholder="Select Age"
              disabled={formType === "view"}
            >
              {selectAgeList &&
                selectAgeList.map((item) => (
                  <Option value={item._id}>{item.age}</Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xl={12} lg={12} md={12} sm={24} xs={24} className="gx-px-0">
          <Form.Item
            name="breed"
            label="Breed"
            rules={[{ required: true, message: "Breed is required" }]}
          >
            <Input readOnly={formType === "view"} placeholder="Enter Breed" />
          </Form.Item>
        </Col>
        <Col
          xl={12}
          lg={12}
          md={12}
          sm={24}
          xs={24}
          className="gx-pl-0 gx-pe-md-1"
        >
          <Form.Item
            name="colorId"
            label="Color"
            rules={[{ required: true, message: "Color is required" }]}
          >
            <Select
              placeholder="Select Color"
              className="gx-text-capitalize"
              disabled={formType === "view"}
            >
              {selectColorList &&
                selectColorList.map((item) => (
                  <Option value={item._id}>{item.colorName}</Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xl={12} lg={12} md={12} sm={24} xs={24} className="gx-px-0">
          <Form.Item
            name="genderId"
            label="Gender"
            rules={[{ required: true, message: "Gender is required" }]}
          >
            <Select
              placeholder="Select Gender"
              className="gx-text-capitalize"
              disabled={formType === "view"}
            >
              {selectGenderList &&
                selectGenderList.map((item) => (
                  <Option value={item._id}>{item.genderType}</Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name="price"
        label="Price"
        rules={[{ required: true, message: "Price is required" }]}
      >
        <Input readOnly={formType === "view"} placeholder="Enter Price" />
      </Form.Item>
      <Form.Item
        name="license"
        label="License"
        rules={[{ required: true, message: "License is required" }]}
      >
        <Input readOnly={formType === "view"} placeholder="Enter Licence" />
      </Form.Item>
      {formType !== "view" && (
        <Form.Item label="Attachment">
          <EditAttachments
            updateAssigned={setPostImages}
            myAssigned={postImages}
          />
        </Form.Item>
      )}
      {formType === "view" && (
        <Form.Item label="Attachment">
          <div
            className="gx-d-flex gx-align-items-center gx-justify-content-center"
            style={{
              height: "124px",
              background: "#F7F8FA",
              borderRadius: "10px",
              overflowY: "hidden",
              overflowX: "auto",
              gap: "12px",
            }}
          >
            {renterImage()}
          </div>
        </Form.Item>
      )}
      <Form.Item
        name="description"
        label="Description"
        rules={[{ required: true, message: "Description is required" }]}
      >
        <TextArea rows={4} readOnly={formType === "view"} />
      </Form.Item>
      <Form.List name="postLinks">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <>
                <div className="gx-d-flex gx-justify-content-end w-100 gx-mb-1">
                  {formType !== "view" && (
                    <MinusCircleOutlined
                      style={{ color: "red" }}
                      onClick={() => remove(name)}
                    />
                  )}
                </div>
                <Row style={{ flexDirection: "row" }}>
                  <Col
                    xl={12}
                    lg={12}
                    md={12}
                    sm={24}
                    xs={24}
                    className="gx-pl-0 gx-pr-3"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "name"]}
                      rules={[
                        {
                          required: true,
                          message: "Name is required",
                        },
                      ]}
                    >
                      <Input
                        readOnly={formType === "view"}
                        className="w-100"
                        placeholder="Enter name"
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xl={12}
                    lg={12}
                    md={12}
                    sm={24}
                    xs={24}
                    className="gx-px-0"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "link"]}
                      rules={[
                        {
                          required: true,
                          message: "Link is required",
                        },
                      ]}
                    >
                      <Input
                        readOnly={formType === "view"}
                        className="w-100"
                        placeholder="Enter link"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                disabled={formType === "view"}
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Add field
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </div>
  );
};

export default Forms;
