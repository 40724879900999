import React, { useEffect, useState } from "react";
import FormModal from "components/Modal/EditFormModal";
import UserForm from "./UserForm";
import { useSelector } from "react-redux";
import { Form } from "antd";
const EditPetMainCategory = () => {
  const [form] = Form.useForm();
  let initialState = {
    loading: false,
    imageUrl: "",
  };
  const [photoList, setPhotoState] = useState(initialState);
  const curdProps = useSelector((state) => state.CurdR);
  const { EDIT_USER_MODAL, initialValues } = curdProps;
  useEffect(() => {
    if (initialValues) {
      setPhotoState({
        loading: false,
        imageUrl: initialValues.profileImageUrl,
      });
    }
  }, [initialValues]);
  return (
    <FormModal
      modalTitle="Edit User"
      editApiName="editUser"
      ModalName="EDIT_USER_MODAL"
      visible={EDIT_USER_MODAL}
      isShowButton={false}
      width={600}
      recordName={"userList"}
      form={form}
      initialValues={initialValues}
      extraFieldName="profileImageUrl"
      extraFieldValue={photoList.imageUrl}
    >
      <UserForm
        formType="edit"
        photoList={photoList}
        setPhotoState={setPhotoState}
      />
    </FormModal>
  );
};

export default EditPetMainCategory;
