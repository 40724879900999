import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ViewModal from "../../../components/Modal/ViewModal";
import Forms from "./Form";
const ViewForm = () => {
  const [postImages, setPostImages] = useState([]);
  const curdProps = useSelector((state) => state.CurdR);
  const { View_Find_MODAL, initialValues } = curdProps;
  useEffect(() => {
    if (initialValues) {
      setPostImages(initialValues.postImages);
    }
  }, [initialValues]);
  return (
    <ViewModal
      modalTitle="View Find Post"
      ModalName="View_Find_MODAL"
      visible={View_Find_MODAL}
      isShowButton={false}
      width={500}
    >
      <Forms postImages={postImages} />
    </ViewModal>
  );
};

export default ViewForm;
