import { Col, Row, Card } from "antd";
import React, { useEffect } from "react";
import ChartCard from "../ChartCard";
import IntlMessages from "../../util/IntlMessages";
import MonthlyAnalysis from "../../components/AreaChart";
import ResetActivity from "../ResentActivity";
import { DataGetAction } from "../../redux/actions/CommonHttp";
import { useDispatch, useSelector } from "react-redux";
import WelComeCard from "../WelComeCard";
import SiteAudience from "../WelComeCard/Graph";
import Widget from "components/Widget";
import StackedBarChart from "../StackedBarChart";
import CustomShapeBarChart from "../CustomShapeBarChart";
import Graph1 from "assets/image/activeUser.png";
import userIcon from "../../assets/image/userIcons.png";
import Graph2 from "../../assets/image/graph2.png";
import postIcon from "../../assets/image/postIcon.png";
import Graph3 from "../../assets/image/graph3.png";
import postIcon1 from "../../assets/image/postIcon-1.png";
import Graph4 from "../../assets/image/grap4.png";
import salePng from "../../assets/image/sales.png";
const Index = () => {
  const dispatch = useDispatch();
  const adminProps = useSelector((state) => state.CurdR);
  const {
    monthlyOverView,
    recentPost,
    halfYearOverView,
    WeeklyOverView,
    AllDashboardData,
    updateDashboardData,
  } = adminProps;

  const fetchAdminDashboard = () => {
    dispatch(
      DataGetAction(
        "getDashboardData",
        "FetchRecord",
        "",
        "",
        "",
        "AllDashboardData"
      )
    );

    dispatch(
      DataGetAction(
        "getMonthlyOverView",
        "FetchRecord",
        "",
        "gettingAdminDashboard",
        "",
        "monthlyOverView"
      )
    );
    dispatch(
      DataGetAction(
        "getRecentPost",
        "FetchRecord",
        "",
        "gettingAdminDashboard",
        "",
        "recentPost"
      )
    );
    dispatch(
      DataGetAction(
        "getHalfYearOverView",
        "FetchRecord",
        "",
        "gettingAdminDashboard",
        "",
        "halfYearOverView"
      )
    );
    dispatch(
      DataGetAction(
        "getWeeklyOverView",
        "FetchRecord",
        "",
        "gettingAdminDashboard",
        "",
        "WeeklyOverView"
      )
    );
  };
  useEffect(fetchAdminDashboard, []);

  useEffect(() => {
    if (updateDashboardData) {
      dispatch({
        type: "AlertOff",
      });
      fetchAdminDashboard();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateDashboardData]);
  return (
    <div>
      <Widget>
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={24}>
            <WelComeCard
              count1={
                (AllDashboardData && AllDashboardData.totalActiveUser) || 0
              }
              count2={(AllDashboardData && AllDashboardData.totalFind) || 0}
              count3={(AllDashboardData && AllDashboardData.totalAdopt) || 0}
              count4={(AllDashboardData && AllDashboardData.totalSale) || 0}
              count5={(AllDashboardData && AllDashboardData.totalCategory) || 0}
            />
          </Col>
          <Col xl={12} lg={12} md={12} sm={12} xs={24} className="gx-audi-col">
            <SiteAudience />
          </Col>
        </Row>
      </Widget>
      <Row>
        <Col xl={6} lg={6} md={12} sm={24} xs={24}>
          <ChartCard
            count={(AllDashboardData && AllDashboardData.totalActiveUser) || 0}
            icon={<img src={userIcon} width="30px" height="30px" alt="" />}
            graph={<img src={Graph1} alt="" width="68px" height="25px" />}
            title={"dashboard.activeUser"}
            bgColor={"#FDDAE5"}
            color="#F9467E"
          />
        </Col>
        <Col xl={6} lg={6} md={12} sm={24} xs={24}>
          <ChartCard
            count={(AllDashboardData && AllDashboardData.totalFind) || 0}
            icon={<img src={postIcon} width="30px" height="30px" alt="" />}
            graph={<img src={Graph2} alt="" width="68px" height="25px" />}
            title={"dashboard.mainCategory"}
            bgColor={"#FDEFB4"}
            color="#31BE7B"
          />
        </Col>
        <Col xl={6} lg={6} md={12} sm={24} xs={24}>
          <ChartCard
            count={(AllDashboardData && AllDashboardData.totalAdopt) || 0}
            icon={<img src={postIcon1} alt="" width="30px" height="30px" />}
            graph={<img src={Graph3} alt="" width="68px" height="25px" />}
            title={"dashboard.subCategory"}
            bgColor={"#E0ECFF"}
            color="#FEE791"
          />
        </Col>
        <Col xl={6} lg={6} md={12} sm={24} xs={24}>
          <ChartCard
            count={(AllDashboardData && AllDashboardData.totalSale) || 0}
            title={"dashboard.adoptPet"}
            bgColor={"#D4F4E8"}
            icon={<img src={salePng} alt="" width="30px" height="30px" />}
            graph={<img src={Graph4} alt="" width="68px" height="25px" />}
            color="#64A2FF"
          />
        </Col>
      </Row>

      <Row>
        <Col xl={15} lg={15} md={15} sm={24} xs={24}>
          <Card
            className="gx-card"
            title={
              <span className="gx-font-sans-medium gx-text-black gx-text-none">
                Half year post details
              </span>
            }
          >
            <StackedBarChart halfYearOverView={halfYearOverView} />
          </Card>
        </Col>
        <Col xl={9} lg={9} md={9} sm={24} xs={24}>
          <ResetActivity
            title="Adopt Pet"
            data={recentPost && recentPost[0].adoptPost}
            route={"post/adoptPet"}
          />
        </Col>
      </Row>
      <Row>
        <Col xl={15} lg={15} md={15} sm={24} xs={24}>
          <Card
            className="gx-card"
            title={
              <span className="gx-font-sans-medium gx-text-black">
                Monthly highlights
              </span>
            }
          >
            <MonthlyAnalysis
              data={monthlyOverView}
              title={<IntlMessages id="dashboard.monthlyAnalysis" />}
              width={"100%"}
              height={274}
              axisX={"name"}
              axisY={"Total Active Post"}
              showNumber={4}
              showYAxis={true}
              startColor={"#ffffff"}
              endcolor={"#51BDA7"}
              strokeColor={"#A2E2D9"}
            />
          </Card>
        </Col>
        <Col xl={9} lg={9} md={9} sm={24} xs={24}>
          <ResetActivity
            title="Find Pet"
            data={recentPost && recentPost[0].findPost}
            route={"post/findPet"}
          />
        </Col>
      </Row>
      <Row>
        <Col xl={15} lg={15} md={15} sm={24} xs={24}>
          <Card
            className="gx-card"
            title={
              <span className="gx-font-sans-medium gx-text-black">
                Daily active users report
              </span>
            }
          >
            <CustomShapeBarChart WeeklyOverView={WeeklyOverView} />
          </Card>
        </Col>
        <Col xl={9} lg={9} md={9} sm={24} xs={24}>
          <ResetActivity
            title="Sale Pet"
            data={recentPost && recentPost[0].salePost}
            route={"post/salePet"}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Index;
