import React from "react";
import FormModal from "components/Modal/EditFormModal";
import PetMainCategoryForm from "./PetMainCategoryForm";
import { intlPlaceholder } from "util/intlPlaceholder";
import { useSelector } from "react-redux";
import { Form } from "antd";
const EditPetMainCategory = () => {
  const [form] = Form.useForm();
  const curdProps = useSelector((state) => state.CurdR);
  const { EDIT_PetMainCategory_MODAL } = curdProps;

  return (
    <div>
      <FormModal
        modalTitle={intlPlaceholder("petMainCategory.editPageTittle")}
        editApiName="editCategory"
        ModalName="EDIT_PetMainCategory_MODAL"
        visible={EDIT_PetMainCategory_MODAL}
        isShowButton={false}
        width={500}
        recordName={"petMainCategoryList"}
        form={form}
      >
        <PetMainCategoryForm />
      </FormModal>
    </div>
  );
};

export default EditPetMainCategory;
