import React from "react";
import SimpleModal from "components/Modal/SimpleModal";
import { useDispatch, useSelector } from "react-redux";
import IntlMessages from "util/IntlMessages";
import { Form, Input, Row, Col, Button, message } from "antd";
import { DataRequestAction } from "redux/actions/CommonHttp";
const ResetPassword = () => {
  const dispatch = useDispatch();
  const authProps = useSelector((state) => state.auth);
  const { fetchLoader } = authProps;
  const curdProps = useSelector((state) => state.CurdR);
  const { RESET_PASSWORD_MODAL, error, success } = curdProps;

  const [form] = Form.useForm();

  const changePassword = (values) => {
    dispatch(
      DataRequestAction(
        "POST",
        "resetPassword",
        "resetPassword",
        values,
        "resetSpinner",
        "RESET_PASSWORD_MODAL",
        ""
      )
    );
  };

  const toggleChangePasswordModal = () => {
    dispatch({
      ModalName: "RESET_PASSWORD_MODAL",
      type: "Hide_Modal",
      payload: null,
    });
    form.resetFields();
  };

  if (error) {
    message.error("Incorrect old Password");
    dispatch({
      type: "ResetAll_State",
    });
  }

  if (success) {
    message.success("Password reset successfully");
    dispatch({
      type: "ResetAll_State",
    });
  }

  return (
    <>
      <SimpleModal
        width={400}
        title={<IntlMessages id="setting.passwordModal.title" />}
        modalFor="RESET_PASSWORD_MODAL"
        visible={RESET_PASSWORD_MODAL ? RESET_PASSWORD_MODAL : false}
      >
        <Form
          layout="vertical"
          name="changePassword"
          onFinish={changePassword}
          form={form}
        >
          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gx-px-0">
              <Form.Item
                className="gx-mt-1"
                name="oldPassword"
                label={<IntlMessages id="setting.oldPassword" />}
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id="setting.oldPassword_message" />,
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                name="password"
                label={<IntlMessages id="setting.password" />}
                rules={[
                  {
                    required: true,
                    message: <IntlMessages id="setting.password_message" />,
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                name="confirmPassword"
                label={<IntlMessages id="setting.confirmPassword" />}
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages id="setting.confirmPassword_message" />
                    ),
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error("Password is not match"));
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gx-px-0">
              <div className="gx-flex gx-flex-row gx-align-items-center gx-justify-content-end ">
                <Form.Item className="gx-mb-0">
                  <Button
                    type="default"
                    className="gx-pt-0 gx-mb-0 gx-mr-2"
                    htmlType="button"
                    onClick={() => toggleChangePasswordModal()}
                  >
                    <IntlMessages id="settings.passwordModal.cancel" />
                  </Button>
                </Form.Item>
                <Form.Item className="gx-mb-0">
                  <Button
                    type="primary"
                    className="gx-pt-0 gx-mb-0"
                    htmlType="submit"
                    loading={fetchLoader}
                    disabled={fetchLoader}
                  >
                    <IntlMessages id="settings.passwordModal.save" />
                  </Button>
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Form>
      </SimpleModal>
    </>
  );
};

export default ResetPassword;
