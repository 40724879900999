import React, { useEffect, useState } from "react";
import FormModal from "components/Modal/EditFormModal";
import Forms from "./Form";
import { useSelector } from "react-redux";
import { Form } from "antd";
const EditPost = () => {
  const [form] = Form.useForm();
  const [postImages, setPostImages] = useState([]);
  const curdProps = useSelector((state) => state.CurdR);
  const { EDIT_Sale_MODAL, initialValues } = curdProps;
  useEffect(() => {
    if (initialValues) {
      setPostImages(initialValues.postImages);
    }
  }, [EDIT_Sale_MODAL, initialValues]);
  return (
    <FormModal
      modalTitle="Edit Post"
      editApiName="editPost"
      ModalName="EDIT_Sale_MODAL"
      visible={EDIT_Sale_MODAL}
      isShowButton={false}
      width={600}
      recordName={"saleList"}
      form={form}
      initialValues={initialValues}
      extraFieldName="postImages"
      extraFieldValue={postImages}
    >
      <Forms
        formType="EditForm"
        setPostImages={setPostImages}
        postImages={postImages}
      />
    </FormModal>
  );
};

export default EditPost;
