import React from "react";

const WelComeCard = ({ count1, count2, count3, count4, count5 }) => {
  return (
    <div className="gx-wel-ema gx-pt-xl-2">
      <h3 className="gx-mb-3 gx-font-sans-medium">Welcome Admin!</h3>
      <p className="gx-fs-sm gx-text-uppercase">You Have</p>
      <ul className="gx-list-group gx-ml-4">
        <li>
          <span>{count1} Active User</span>
        </li>
        <li>
          <span>{count2} Total Found Post</span>
        </li>
        <li>
          <span>{count5} Total Pet Category</span>
        </li>
        <li>
          <span>{count3} Total Adopt Post</span>
        </li>
        <li>
          <span>{count4} Total Sale Post</span>
        </li>
      </ul>
    </div>
  );
};

export default WelComeCard;
