import React from "react";
import Logo from "../../../assets/image/loginScreen.jpg";
import { Row, Col } from "antd";

const AuthLeftSide = ({ children }) => {
  return (
    <Row className="gx-lighter-bg-color gx-h-100 gx-d-flex gx-align-items-center gx-justify-content-center">
      <Col
        xl={12}
        lg={12}
        md={12}
        sm={24}
        xs={24}
        className="gx-bg-transparent gx-d-md-block gx-d-none gx-h-100"
      >
        <div style={{ position: "relative" }} className="gx-d-flex gx-h-100">
          <img src={Logo} alt="" height="100%" width="100%" />
          <span
            className=" gx-ml-4 gx-mt-4 gx-d-flex gx-align-items-center gx-justify-content-center"
            style={{
              position: "absolute",
              color: "#AE0700",
              background: "#ffff",
              height: "50px",
              width: "200px",
              borderRadius: "10px",
            }}
          >
            <span  style={{ color: "#AE0700" , fontSize:"20px" }}>Pet Pal</span>{" "}
          </span>
        </div>
      </Col>
      <Col xl={12} lg={12} md={12} sm={24} xs={24} style={{ height: "92%" }}>
        {children}
      </Col>
    </Row>
  );
};

export default AuthLeftSide;
