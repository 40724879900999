import React from "react";
import FormModal from "components/Modal/FormModal";
import AgeForm from "./AgeForm";
import { intlPlaceholder } from "util/intlPlaceholder";
import { useSelector } from "react-redux";
import { Form } from "antd";
const AddPetMainCategory = () => {
  const [form] = Form.useForm();
  const { ADD_AGE_MODAL } = useSelector((state) => state.CurdR);
  return (
    <FormModal
      addButtonName={intlPlaceholder("age.addAge")}
      modalTitle={intlPlaceholder("age.addAgeTitle")}
      addApiName="addAge"
      ModalName="ADD_AGE_MODAL"
      visible={ADD_AGE_MODAL}
      width={500}
      recordName={"ageList"}
      form={form}
    >
      <AgeForm />
    </FormModal>
  );
};

export default AddPetMainCategory;
