//components/ChartCard
import React from "react";
import Widget from "components/Widget";
import { Col, Row } from "antd";
import { intlPlaceholder } from "util/intlPlaceholder";

const ChartCard = ({ icon, count, graph, title, bgColor = "#FDDAE5" }) => {
  return (
    <Widget className="gx-card-full gx-p-3">
      <div className="gx-d-flex gx-align-items-center" style={{ gap: "12px" }}>
        <span
          className="gx-d-flex gx-align-items-center gx-justify-content-center"
          style={{
            background: bgColor,
            width: "60px",
            height: "50px",
            borderRadius: "10px",
          }}
        >
          {icon}
        </span>
        <div className="gx-w-100">
          <span className="gx-font-sans-medium" style={{ fontSize: "16px" }}>
            {title ? intlPlaceholder(title) : "Test Active User"}
          </span>
          <Row className="gx-m-0 gx-w-100">
            <Col xl={8} lg={8} md={8} sm={8} xs={8} className="gx-px-0">
              <span
                className="gx-font-sans-medium"
                style={{ fontSize: "24px" }}
              >
                {count || 0}
              </span>
            </Col>
            <Col
              xl={16}
              lg={16}
              md={16}
              sm={16}
              xs={16}
              className="gx-d-flex gx-justify-content-end gx-px-0"
            >
              {graph}
            </Col>
          </Row>
        </div>
      </div>
    </Widget>
  );
};

export default ChartCard;
