const INIT_STATE = {
  dashboardData: [
    {
      totalQuotations: 780,
      totalOrders: 280,
      inProgressOrders: 500,
      completeOrders: 500,
    },
  ],
  monthlyAnalysis: [],
  textMessage: [],
  success: false,
  error: false,
  fetchingLoader: false,
  revenueOverview: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "gettingAdminDashboard_START": {
      return {
        ...state,
        fetchingLoader: true,
        error: false,
        success: false,
      };
    }

    case "getDashboardOverview_SUCCESS": {
      return {
        ...state,
        dashboardData: [...action.payload.Record],
        fetchingLoader: false,
        error: false,
        success: true,
      };
    }
    case "getDashboardOverview_FAILURE": {
      return {
        ...state,
        textMessage: "could not fetch data due to api failure",
        error: true,
        success: false,
      };
    }
    case "getMonthlyOverview_SUCCESS": {
      return {
        ...state,
        monthlyAnalysis: action.payload.Record,
        fetchingLoader: false,
      };
    }
    case "getRevenueDashboard_SUCCESS": {
      return {
        ...state,
        revenueOverview: action.payload.Record,
      };
    }
    case "reset-admin-dashboard": {
      return {
        ...state,
        error: false,
        success: false,
        fetchingLoader: false,
      };
    }

    default:
      return state;
  }
};
