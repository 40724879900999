import React from "react";
import Layout from "components/Layout";
import Widget from "components/Widget";
import UserList from "components/Table/List";
import TableHeader from "./components/TableHeader";
import { useHistory } from "react-router";

const Index = () => {
  const history = useHistory();
  const columns = [
    {
      title: "Id",
      render: (record) => <span>{record.id}</span>,
      key: "Id",
      width: "100px",
    },
    {
      title: "Keyword Search",
      render: (record) => <span>{record.keyword || "-----"}</span>,
      key: "Keyword",
      width: "250px",
    },
    {
      title: "Count",
      render: (record) => (
        <span className="gx-text-capitalize">{record.count || "---"}</span>
      ),
      key: "Count",
      width: "250px",
    },
    {
      title: "View",
      render: (record) => (
        <span
          style={{ cursor: "pointer" }}
          onClick={() =>
            history.push({
              pathname: "/userDetail",
              state: record,
            })
          }
          className="gx-text-primary"
        >
          User Detail
        </span>
      ),
      key: "View",
      width: "200px",
    },
  ];

  return (
    <Layout>
      <Widget>
        <TableHeader />

        <UserList
          apiName="getSearchWord"
          recordName="searchWordList"
          columns={columns}
          scroll={true}
          pagination={true}
        />
      </Widget>
    </Layout>
  );
};

export default Index;
