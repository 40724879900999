import React, { useEffect, useState } from "react";
import FormModal from "components/Modal/FormModal";
import NewsForm from "./NewsForm";
import { intlPlaceholder } from "util/intlPlaceholder";
import { useSelector } from "react-redux";
import { Form } from "antd";
const AddNews = () => {
  const [form] = Form.useForm();
  const [myAssigned, updateAssigned] = useState([]);
  const { ADD_News_MODAL } = useSelector((state) => state.CurdR);
  useEffect(() => {
    updateAssigned([]);
  }, [ADD_News_MODAL]);
  return (
    <FormModal
      addButtonName={intlPlaceholder("news.addNews")}
      modalTitle={intlPlaceholder("news.addNewsTitle")}
      addApiName="addNews"
      addApiRequest="AddRecord"
      ModalName="ADD_News_MODAL"
      visible={ADD_News_MODAL}
      width={500}
      recordName="newsList"
      form={form}
      extraFieldName="fileUrl"
      extraFieldValue={myAssigned}
    >
      <NewsForm updateAssigned={updateAssigned} myAssigned={myAssigned} />
    </FormModal>
  );
};

export default AddNews;
