import React from "react";
import SimpleModal from "../../../components/Modal/SimpleModal";
import { useSelector } from "react-redux";

const ViewOtpModal = () => {
  const optProps = useSelector((state) => state.CurdR);
  const { View_Otp_Modal, initialValues } = optProps;

  return (
    <SimpleModal
      width="300px"
      modalFor="View_Otp_Modal"
      visible={View_Otp_Modal}
    >
      <div
        className="gx-mt-3 gx-d-flex gx-align-items-center gx-justify-content-center"
        style={{ fontSize: "30px", fontWeight: "700" }}
      >
        {initialValues && initialValues.phoneNumberOtp}
      </div>
    </SimpleModal>
  );
};

export default ViewOtpModal;
