import React, { useState, useEffect } from "react";
import Widget from "components/Widget";
import { Row, Col, Typography, Image } from "antd";
import IntlMessages from "util/IntlMessages";
import dummyImage from "assets/images/dummyImage.jpeg";
import { useDispatch, useSelector } from "react-redux";
import EditSetting from "./components/EditSetting";
import ResetPassword from "./components/ResetPassword";
import Layout from "components/Layout";
import ShowModal from "components/Modal/ShowModal";
import { DataGetAction } from "redux/actions/CommonHttp";
const { Title } = Typography;

const Index = () => {
  const dispatch = useDispatch();

  const styles = {
    img: {
      width: "50px",
      height: "50px",
      borderRadius: "50%",
    },
  };
  let initialState = {
    loading: false,
    imageUrl: "",
  };
  const [photoList, setPhotoState] = useState(initialState);

  const settingsProps = useSelector((state) => state.auth);
  const { settingsData } = settingsProps;
  const fetchSettings = () => {
    dispatch(
      DataGetAction(
        "getProfile",
        "getProfile",
        "",
        "gettingProfile",
        "",
        "settingsData"
      )
    );
    dispatch(
      DataGetAction("getSetting", "getSetting", "", "StartSpinner", "", "")
    );
    if (settingsData && settingsData.profileImageUrl) {
      photoList.imageUrl = settingsData.profileImageUrl;
    }
  };

  useEffect(fetchSettings, [settingsData.profileImageUrl]);
  const phoneRegex = /^\(?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
  return (
    <Layout>
      <Row>
        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
          <Widget>
            <div className="gx-d-flex gx-justify-content-between">
              <div
                className="gx-d-flex gx-align-items-center"
                style={{ gap: "15px" }}
              >
                <Image
                  style={styles.img}
                  src={
                    settingsData && settingsData.profileImageUrl
                      ? settingsData.profileImageUrl
                      : dummyImage
                  }
                />

                <div>
                  <Title
                    level={5}
                    className="gx-mb-0 gx-text-dark gx-font-sans-bold"
                  >
                    <span>{settingsData && settingsData.fullName}</span>
                  </Title>
                  <ShowModal
                    record={null}
                    modalName={"RESET_PASSWORD_MODAL"}
                    className="gx-text-success"
                    buttonName="setting.changePassword"
                  />
                </div>
              </div>
              <ShowModal record={null} modalName={"EDIT_PROFILE_MODAL"}>
                <span
                  className="icon icon-edit gx-text-dark gx-d-flex gx-align-items-center gx-justify-content-center gx-pointer"
                  style={{
                    border: "1px solid #D2D2D280",
                    borderRadius: "6px",
                    height: "36px",
                    width: "36px",
                  }}
                ></span>
              </ShowModal>
            </div>

            <div className="gx-d-flex gx-mt-4" style={{ gap: "15px" }}>
              <span
                className="icon icon-email gx-text-primary"
                style={{ fontSize: "20px" }}
              ></span>
              <div>
                <span className="gx-text-light gx-font-sans-regular">
                  <IntlMessages id="setting.email" />
                </span>
                <p>{(settingsData && settingsData.email) || "www@yahoo.com"}</p>
              </div>
            </div>

            <div className="gx-d-flex gx-mt-2" style={{ gap: "15px" }}>
              <span
                className="icon icon-phone  gx-text-primary"
                style={{ fontSize: "20px" }}
              ></span>
              <div>
                <span className="gx-text-light gx-font-sans-regular">
                  <IntlMessages id="setting.phone" />
                </span>
                <p>
                  +{" "}
                  {settingsData &&
                    settingsData.phoneNumber &&
                    settingsData.phoneNumber.replace(phoneRegex, "$1 $2 $3")}
                </p>
              </div>
            </div>
          </Widget>
        </Col>
      </Row>
      <ResetPassword />
      <EditSetting photoList={photoList} setPhotoState={setPhotoState} />
    </Layout>
  );
};

export default Index;
