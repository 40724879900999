import React from "react";
import { Button, Modal, Form, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { DataRequestAction } from "../../redux/actions/CommonHttp";

const CollectionCreateForm = ({
  visible,
  onCreate,
  onCancel,
  modalTitle,
  submitButtonText = "Save",
  cancelButtonText = "Cancel",
  children,
  width,
  className,
  showFooter,
  form,
  screenName,
}) => {
  const CrudProps = useSelector((state) => state.CurdR);
  const { Loader } = CrudProps;
  if (!visible) {
    form.resetFields();
  }

  return (
    <div>
      <Modal
        visible={visible}
        maskClosable={false}
        title={<span className="gx-font-sans-bold">{modalTitle}</span>}
        width={width}
        form={form}
        bodyStyle={{
          paddingLeft: 0,
          paddingRight: 0,
        }}
        okText={submitButtonText}
        cancelText={cancelButtonText}
        className={className}
        onCancel={() => {
          form.resetFields();
          onCancel();
        }}
        okButtonProps={{ disabled: showFooter }}
        confirmLoading={Loader}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              onCreate(values);
            })
            .catch((info) => {});
        }}
      >
        <Form form={form} layout="vertical" name="form_in_modal">
          {children}
        </Form>
      </Modal>
    </div>
  );
};

const CollectionsPage = ({
  children,
  modalTitle,
  submitButtonText,
  cancelButtonText,
  apiMethod = "POST",
  addApiName,
  addApiRequest = "AddRecord",
  visible,
  ModalType = "Hide_Modal",
  width,
  className,
  apiLoader = "StartSpinner",
  addButtonShow = true,
  extraFieldName,
  secondExtraFieldName,
  secondExtraFieldValue,
  extraFieldValue,
  payload = null,
  addButtonName = "Add",
  showFooter,
  ModalName,
  recordName,
  screenName,
  textMessage,
  disabled = false,
  form,
}) => {
  const dispatch = useDispatch();
  const CrudProps = useSelector((state) => state.CurdR);
  const { Loader } = CrudProps;
  const onCreate = (values) => {
    if (values.email) {
      values.email = values.email.toLowerCase();
    }

    if (extraFieldName) {
      values[extraFieldName] = extraFieldValue;
    }
    if (secondExtraFieldName) {
      values[secondExtraFieldName] = secondExtraFieldValue;
    }
    if (values && values.username) {
      values.displayUserName = values.username;
    }
    if (screenName === "banner") {
      if (extraFieldName && extraFieldValue) {
        dispatch(
          DataRequestAction(
            apiMethod,
            addApiName,
            addApiRequest,
            values,
            apiLoader,
            ModalName,
            recordName
          )
        );
      } else {
        message.info("Please enter Banner Image");
      }
    } else {
      dispatch(
        DataRequestAction(
          apiMethod,
          addApiName,
          addApiRequest,
          values,
          apiLoader,
          ModalName,
          recordName
        )
      );
    }
  };

  return (
    <div>
      {addButtonShow && (
        <Button
          type="primary"
          className="gx-pt-0 gx-m-0 gradient-button"
          onClick={() => {
            dispatch({
              type: "Show_Modal",
              payload: payload,
              ModalName: ModalName,
            });
          }}
        >
          {addButtonName}
        </Button>
      )}
      <CollectionCreateForm
        modalTitle={modalTitle}
        submitButtonText={submitButtonText}
        cancelButtonText={cancelButtonText}
        apiMethod={apiMethod}
        ModalType={ModalType}
        confirmLoading={Loader}
        apiLoaderName={apiLoader}
        visible={visible}
        width={width}
        className={className}
        showFooter={showFooter}
        onCreate={onCreate}
        screenName={screenName}
        form={form}
        onCancel={() => {
          dispatch({
            type: ModalType,
            payload: null,
            ModalName: ModalName,
            clear: true,
          });
        }}
      >
        {children}
      </CollectionCreateForm>
    </div>
  );
};

export default CollectionsPage;
