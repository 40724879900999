import moment from "moment/moment";
import React from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

function formatXAxis(tickItem) {
  // If using moment.js
  if (tickItem) {
    return moment(tickItem).format("ddd");
  } else {
    return "";
  }
}
const CustomShapeBarChart = ({ WeeklyOverView }) => (
  <ResponsiveContainer width="100%" height={277}>
    <LineChart
      data={WeeklyOverView}
      syncId="anyId"
      margin={{ top: 10, right: 0, left: -15, bottom: 0 }}
    >
      <XAxis dataKey="name" tickFormatter={formatXAxis} />
      <YAxis />
      <CartesianGrid strokeDasharray="3 3" />
      <Tooltip />
      <Line
        type="monotone"
        dataKey="TotalUser"
        stroke="#003366"
        fill="#003366"
      />
    </LineChart>
  </ResponsiveContainer>
);

export default CustomShapeBarChart;
