import store from "../store";

export const SocketResponseState = (type, payload, recordName) => {
  store.dispatch({
    type: `${type}_SUCCESS`,
    payload: { Record: payload },
    recordName: recordName,
  });
  if (payload) {
    store.dispatch({
      type: "redDotShowAndHide",
      payload: true,
    });
  }
};
