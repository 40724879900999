import React from "react";
import Layout from "components/Layout";
import Widget from "components/Widget";
import BroadcastList from "components/Table/List";
import Action from "components/Table/Actions";
import TableHeader from "./components/TableHeader";
import ShowModal from "components/Modal/ShowModal";
import DeleteConfirm from "components/Modal/DeleteConfirm";
import moment from "moment/moment";
import ViewDescription from "./components/ViewDescription";

const Index = () => {
  const renderActions = (record) => {
    return (
      <div className="gx-d-flex gx-flex-column">
        <DeleteConfirm
          type="deleteBroadCast"
          apiRequest="DeleteRecord"
          recordName="broadcastList"
          title="Are you sure you want to delete this Broadcast"
          content="You can't undo this action"
          selectedItem={record}
          Icon="Delete"
        />
      </div>
    );
  };

  const columns = [
    {
      title: "Title",
      render: (record) => (
        <span className="gx-text-capitalize">{record.title}</span>
      ),
      key: "title",
      width: "150px",
    },

    {
      title: "Created At",
      render: (record) => (
        <span>{moment(record.createdAt).format("MMM-DD-YYYY")}</span>
      ),
      key: "createdAt",
      width: "50px",
    },
    {
      title: "Description",
      render: (record) => {
        return (
          <span className="gx-text-capitalize gx-link gx-text-blue">
            <span className="gx-text-capitalize gx-link gx-link  gx-overflow-ellipsis">
              {
                <ShowModal modalName={"ViewDescription_Modal"} record={record}>
                  {record.description
                    ? record.description.length > 80
                      ? record.description.slice(0, 80) + "...."
                      : record.description
                    : ""}
                </ShowModal>
              }
            </span>
          </span>
        );
      },
      key: "description",
      width: "300px",
    },
    {
      title: "",
      render: (record) => <Action>{renderActions(record)}</Action>,
      key: "action",
      width: "50px",
    },
  ];
  return (
    <Layout>
      <Widget>
        <TableHeader />
        <BroadcastList
          apiName="getBroadCast"
          recordName="broadcastList"
          columns={columns}
          scroll={true}
          pagination={true}
        />
         <ViewDescription/>
      </Widget>
    </Layout>
  );
};

export default Index;
