import React from "react";
import { Upload } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
const Index = ({ name, setPhotoState, photoList }) => {
  const token = localStorage.getItem("token");

  const { loading, imageUrl } = photoList;

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setPhotoState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.

      setPhotoState({
        imageUrl: info.file.response.url.location,
        loading: false,
      });
    }
  };

  const uploadButton = (
    <div>
      {loading ? (
        <LoadingOutlined />
      ) : (
        <div>
          {" "}
          <span
            className="icon icon-image gx-d-block"
            style={{ fontSize: "33px", opacity: "0.3" }}
          ></span>
          <span>upload picture</span>
        </div>
      )}
    </div>
  );

  const ImageProps = {
    action: `${process.env.REACT_APP_ROOT_URL}/uploadProfileImage`,
    method: "POST",
    headers: {
      Authorization: token,
    },
    body: { name },
    onChange: handleChange,
    multiple: false,
    listType: "picture-card",
    className: "avatar-uploader-square",
    showUploadList: false,
  };

  return (
    <Upload style={{ overflow: "hidden" }} {...ImageProps}>
      {imageUrl ? (
        <img
          src={imageUrl}
          alt="avatar"
          style={{
            width: "550px",
            height: "148px",
            objectFit: "cover",
            borderRadius: "6px",
          }}
        />
      ) : (
        uploadButton
      )}
    </Upload>
  );
};

export default Index;
