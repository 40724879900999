import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const BiaxialBarChart = ({ halfYearOverView }) => (
  <ResponsiveContainer width="100%" height={277}>
    <BarChart
      data={halfYearOverView}
      margin={{ top: 10, right: 0, left: -15, bottom: 0 }}
    >
      <CartesianGrid strokeDasharray="2 2" />
      <XAxis dataKey="name" />
      <YAxis domain={[0, (dataMax) => dataMax + 2]} />
      <Tooltip />
      <Legend />

      <Bar stackId="left" dataKey="FindPet" fill="#03275b" />
      <Bar stackId="center" dataKey="AdoptPet" fill="#FB9434" />
      <Bar stackId="right" dataKey="SalePet" fill="#F93333" />
    </BarChart>
  </ResponsiveContainer>
);

export default BiaxialBarChart;
