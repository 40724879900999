import React from "react";
import Layout from "components/Layout";
import Widget from "components/Widget";
import UserList from "components/Table/List";
import TableHeader from "./UserDetailHeader";
import { useLocation } from "react-router-dom";

const UserDetail = () => {
  const params = useLocation();
  const { state } = params;
  const columns = [
    {
      title: "Id",
      render: (record) => <span>{record.id}</span>,
      key: "userId",
    },
    {
      title: "User Name",
      render: (record) => <span>{record.username || "-----"}</span>,
      key: "userName",
    },
    {
      title: "Full Name",
      render: (record) => <span>{record.fullName || "-----"}</span>,
      key: "fullName",
    },
    {
      title: "Email",
      render: (record) => (
        <span className="gx-text-capitalize">{record.email || "---"}</span>
      ),
      key: "email",
    },
    {
      title: "Phone Number",
      render: (record) => (
        <span className="gx-text-capitalize">
          {record.phoneNumber || "---"}
        </span>
      ),
      key: "phoneNumber",
    },
    {
      title: "Search Count",
      render: (record) => (
        <span className="gx-text-capitalize">{record.count || "---"}</span>
      ),
      key: "count",
    },
  ];

  return (
    <Layout>
      <Widget>
        <TableHeader state={state} />
        <UserList
          apiName="getDetailByKeyword"
          recordName="DetailByKeywordList"
          columns={columns}
          scroll={true}
          pagination={true}
          customQuery={{ keyword: state.keyword }}
        />
      </Widget>
    </Layout>
  );
};

export default UserDetail;
