import React, { useEffect, useState } from "react";
import FormModal from "components/Modal/EditFormModal";
import NewsForm from "./NewsForm";
import { intlPlaceholder } from "util/intlPlaceholder";
import { useSelector } from "react-redux";
import { Form } from "antd";
const EditNews = () => {
  const [myAssigned, updateAssigned] = useState([]);

  const [form] = Form.useForm();
  const curdProps = useSelector((state) => state.CurdR);
  const { EDIT_News_MODAL, initialValues } = curdProps;

  useEffect(() => {
    if (initialValues) {
      updateAssigned(initialValues.fileUrl);
    }
  }, [EDIT_News_MODAL, initialValues]);

  return (
    <div>
      <FormModal
        modalTitle={intlPlaceholder("news.editPageTittle")}
        editApiName="editNews"
        ModalName="EDIT_News_MODAL"
        visible={EDIT_News_MODAL}
        isShowButton={false}
        width={500}
        recordName={"newsList"}
        form={form}
        extraFieldName="fileUrl"
        extraFieldValue={myAssigned}
      >
        <NewsForm updateAssigned={updateAssigned} myAssigned={myAssigned} />
      </FormModal>
    </div>
  );
};

export default EditNews;
