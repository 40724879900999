import React from "react";
import { Popover } from "antd";
import { MoreOutlined } from "@ant-design/icons";
const Index = ({ children }) => {
  return (
    <Popover placement="bottom" content={children} title="" trigger="hover">
      <span
        className="gx-pointer gx-d-flex"
        style={{ fontSize: "28px", fontWeight: "bold" }}
      >
        <MoreOutlined />
      </span>
    </Popover>
  );
};

export default Index;
