import React from "react";
import SimpleModal from "components/Modal/SimpleModal";
import { useSelector } from "react-redux";

const ViewDescription = () => {
  const curdProps = useSelector((state) => state.CurdR);
  const { ViewDescription_Modal, initialValues } = curdProps;

  return (
    <>
      <SimpleModal
        width={530}
        modalFor="ViewDescription_Modal"
        visible={ViewDescription_Modal}
      >
        <h3>Description</h3>{" "}
        <span className="gx-p-2">
          <p>{initialValues && initialValues.description}</p>
        </span>
      </SimpleModal>
    </>
  );
};

export default ViewDescription;
