import React from "react";
import { Form, Input } from "antd";
import { intlPlaceholder } from "util/intlPlaceholder";
const PetMainCategoryForm = () => {
  return (
    <Form.Item
      className="gx-px-3"
      name="genderType"
      label={intlPlaceholder("gender.filedName")}
      rules={[
        {
          required: true,
          message: "Gender is required",
        },
      ]}
    >
      <Input placeholder={intlPlaceholder("gender.filedName_Placeholder")} />
    </Form.Item>
  );
};

export default PetMainCategoryForm;
