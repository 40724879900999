import React from "react";
import { DatePicker } from "antd";
import { useDispatch } from "react-redux";
import { string, any } from "prop-types";
import { DataGetAction } from "redux/actions/CommonHttp";
const { RangePicker } = DatePicker;

const DateRangPicker = ({
  apiName,
  placeholder,
  recordName,
  className,
  customQuery,
  width,
}) => {
  const dispatch = useDispatch();
  const onChange = (data) => {
    if (data) {
      dispatch(
        DataGetAction(
          apiName,
          "FetchRecord",
          {
            ...customQuery,
            startDate: data[0],
            endDate: data[1],
          },
          "StartSpinner",
          "",
          recordName
        )
      );
    } else {
      dispatch(
        DataGetAction(
          apiName,
          "FetchRecord",
          { query: "all" },
          "StartSpinner",
          "",
          recordName
        )
      );
    }
  };

  return (
    <RangePicker
      className={className}
      style={{ width: width }}
      placeholder={placeholder}
      onChange={onChange}
    />
  );
};

DateRangPicker.propTypes = {
  apiName: string,
  placeholder: string,
  loaderName: string,
  className: any,
};

export default DateRangPicker;
