import React from "react";
import Layout from "components/Layout";
import Widget from "components/Widget";
import AdoptPetList from "components/Table/List";
import Action from "components/Table/Actions";
import TableHeader from "./components/TableHeader";
import { intlPlaceholder } from "util/intlPlaceholder";
import { DataRequestAction } from "../../redux/actions/CommonHttp";
import { renderStatus } from "utils/commonUseFunction";
import DeleteConfirm from "../../components/Modal/DeleteConfirm";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import ShowModal from "../../components/Modal/ShowModal";
import ViewForm from "./components/ViewForm";
import ViewLinksModal from "./components/ViewLinksModal";
import EditPost from "./components/EditPost";
import { useHistory } from "react-router-dom";
const Index = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { View_Links_MODAL, EDIT_Adopt_MODAL } = useSelector(
    (state) => state.CurdR
  );
  const changeStatus = (id, status, api) => {
    dispatch(
      DataRequestAction(
        "Put",
        api,
        "EditRecord",
        {
          _id: id,
          status: status,
        },
        "StartSpinner",
        "",
        "adoptPetList",
        `${status} Successfully`,
        ""
      )
    );
  };

  const renderActions = (record) => {
    return (
      <div className="gx-d-flex gx-flex-column " style={{ width: "100px" }}>
        {record.status === "active" && (
          <span
            className="gx-pointer"
            onClick={() => changeStatus(record._id, "block", "BlockPost")}
          >
            Block
          </span>
        )}
        {record.status === "block" && (
          <span
            className="gx-pointer"
            onClick={() => changeStatus(record._id, "active", "activePost")}
          >
            Active
          </span>
        )}
        <ShowModal
          modalName="View_Adopt_MODAL"
          buttonName="View"
          record={record}
        />
        <ShowModal
          modalName="EDIT_Adopt_MODAL"
          buttonName="Edit"
          record={record}
        />
        <DeleteConfirm
          type="deletePost"
          apiRequest="DeleteRecord"
          recordName="adoptPetList"
          title="Are you sure you want to delete this Post"
          content="You can't undo this action"
          selectedItem={record}
          Icon="Delete"
        />
      </div>
    );
  };

  const columns = [
    {
      title: intlPlaceholder("findPet.id"),
      render: (record) => (
        <span style={{ fontSize: "12px" }}>{record.postId}</span>
      ),
      key: "findPetId",
    },
    {
      title: "User Info",
      render: (record) => (
        <div className="gx-d-flex gx-flex-column">
          <span
            className="gx-text-capitalize"
            style={{ fontSize: "14px", fontWeight: "bold", color: "#000" }}
          >
            {record.name}
          </span>
          <span style={{ color: "#949494", fontSize: "12px" }}>
            {record.email}
          </span>
          <span style={{ color: "#949494", fontSize: "12px" }}>
            {record.phoneNumber}
          </span>
        </div>
      ),
      key: "userInfo",
    },
    {
      title: "Post Detail",
      render: (record) => (
        <div className="gx-d-flex gx-flex-column">
          <span
            className="gx-text-capitalize"
            style={{ fontSize: "14px", fontWeight: "bold", color: "#000" }}
          >
            {record.title}
          </span>
          <span style={{ color: "#949494", fontSize: "12px" }}>
            {record.description && record.description.length > 30
              ? record.description.slice(0, 30) + "..."
              : record.description}
          </span>
        </div>
      ),
      key: "userInfo",
    },
    {
      title: "Category",
      render: (record) => (
        <div className="gx-d-flex gx-flex-column">
          <span
            className="gx-text-capitalize"
            style={{ fontSize: "14px", fontWeight: "bold", color: "#000" }}
          >
            {record.categoryName}
          </span>
          <span style={{ color: "#949494", fontSize: "12px" }}>
            {record.breed}
          </span>
        </div>
      ),
      key: "Category",
    },
    {
      title: "Clicks",
      render: (record) => (
        <span
          onClick={() => {
            if (record.postClick) {
              history.push({
                pathname: "/viewUsers",
                state: {
                  postType: record.postType,
                  _id: record._id,
                  title: record.title,
                },
              });
            }
          }}
          style={{ cursor: "pointer", fontSize: "12px" }}
          className="gx-text-primary"
        >
          {(record && record.postClick) || 0} View
        </span>
      ),
      key: "postClick",
      width: "80px",
    },

    {
      title: "Age",
      render: (record) => (
        <span className="gx-text-capitalize" style={{ fontSize: "12px" }}>
          {(record && record.age) || "----"}
        </span>
      ),
      key: "age",
    },
    {
      title: "Color",
      render: (record) => (
        <span className="gx-text-capitalize" style={{ fontSize: "12px" }}>
          {(record && record.colorName) || "----"}
        </span>
      ),
      key: "colorName",
    },
    {
      title: "Gender",
      render: (record) => (
        <span className="gx-text-capitalize" style={{ fontSize: "12px" }}>
          {(record && record.genderType) || "----"}
        </span>
      ),
      key: "genderType",
    },
    {
      title: "Links",
      render: (record) => (
        <span style={{ fontSize: "12px" }}>
          {record && record.postLinks && record.postLinks.length > 0 ? (
            <ShowModal
              modalName="View_Links_MODAL"
              buttonName="View"
              className="gx-link"
              record={record.postLinks}
            />
          ) : (
            "---"
          )}
        </span>
      ),
      key: "Links",
      width: "80px",
    },
    {
      title: "Date",
      render: (record) => (
        <span style={{ fontSize: "12px" }}>
          {moment(record.createdAt).format("MMM-DD-YYYY")}
        </span>
      ),
      key: "date",
    },
    {
      title: intlPlaceholder("findPet.status"),
      render: (record) => <span>{renderStatus(record.status)}</span>,
      key: "status",
    },
    {
      title: "",
      render: (record) => (
        <>
          {record.status !== "deleted" && (
            <Action>{renderActions(record)}</Action>
          )}
        </>
      ),
      key: "action",
      width: "50px",
    },
  ];
  return (
    <Layout>
      <Widget>
        <TableHeader />
        <AdoptPetList
          apiName="getPost"
          recordName="adoptPetList"
          columns={columns}
          scroll={true}
          pagination={true}
          customQuery={{ type: "adopt" }}
        />
      </Widget>
      <ViewForm />
      {EDIT_Adopt_MODAL && <EditPost />}
      {View_Links_MODAL && <ViewLinksModal />}
    </Layout>
  );
};

export default Index;
