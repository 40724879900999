import React from "react";
import { Modal, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { logoutFn } from "redux/actions/CommonHttp";
import IntlMessages from "util/IntlMessages";
const SimpleModal = () => {
  const authProps = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const logoutUser = () => {
    dispatch(logoutFn());
  };

  return (
    <Modal
      visible={authProps.unAuthorized_modal}
      centered
      footer={false}
      maskClosable={false}
      closable={false}
    >
      <div className="gx-text-center">
        <h2>{<IntlMessages id="unauthorized.message" />}</h2>
        <Button
          className="gx-mt-3 gx-pt-0"
          onClick={() => {
            dispatch({
              type: "UN_AUTHORIZED_close",
            });
            logoutUser();
          }}
        >
          {<IntlMessages id="logIn.btn" />}
        </Button>
      </div>
    </Modal>
  );
};

export default SimpleModal;
