import React, { useEffect, useState } from "react";
import FormModal from "components/Modal/FormModal";
import BannerFrom from "./BannerFrom";
import { useSelector } from "react-redux";
import { Form } from "antd";
const AddBanner = () => {
  const [form] = Form.useForm();
  let initialState = {
    loading: false,
    imageUrl: "",
  };
  const [photoList, setPhotoState] = useState(initialState);
  const { ADD_Banner_MODAL } = useSelector((state) => state.CurdR);
  useEffect(() => {
    if (ADD_Banner_MODAL) {
      setPhotoState({
        loading: false,
        imageUrl: "",
      });
    }
  }, [ADD_Banner_MODAL]);

  return (
    <FormModal
      addButtonName="Add Banner"
      modalTitle="Add New Banner"
      addApiName="addBanner"
      ModalName="ADD_Banner_MODAL"
      visible={ADD_Banner_MODAL}
      width={600}
      recordName={"bannerList"}
      form={form}
      extraFieldName="profileImageUrl"
      extraFieldValue={photoList.imageUrl}
      screenName={"banner"}
    >
      <BannerFrom
        form={form}
        formType={"add"}
        setPhotoState={setPhotoState}
        photoList={photoList}
      />
    </FormModal>
  );
};

export default AddBanner;
