import React from "react";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { Button } from "antd";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
const GeneratePdf = ({ data, keyword }) => {
  let headerBodyData = [
    [
      { text: "Id", style: "tableHeader" },
      { text: "User Name", style: "tableHeader" },
      { text: "Full Name", style: "tableHeader" },
      { text: "Email", style: "tableHeader" },
      { text: "Phone Number", style: "tableHeader" },
      { text: "Count", style: "tableHeader" },
    ],
  ];
  const downloadSummaryPdf = async () => {
    let Record = data;
    let bodyData = [...headerBodyData];
    Record.forEach((Item) => {
      let userId = Item.id;
      let username = Item.username;
      let fullName = Item.fullName;
      let email = Item.email;
      let phoneNumber = Item.phoneNumber;
      let count = Item.count;
      let objArr = [];
      objArr.push(userId);
      objArr.push(username);
      objArr.push(fullName);
      objArr.push(email);
      objArr.push(phoneNumber);
      objArr.push(count);
      bodyData = [...bodyData, objArr];
    });

    let docDefinition = {
      pageMargins: [10, 50, 10, 45],
      pageOrientation: "landscape",
      content: [
        {
          text: `Keyword: ${keyword}`,
          style: { fontSize: 22, bold: true, alignment: "left" },
          margin: [15, 10],
        },
        {
          style: "tableBody",
          headerRows: 1,
          margin: [15, 10],
          table: {
            widths: ["*", "*", "*", "*", "*", "*"],
            body: bodyData,
          },
        },
      ],

      styles: {
        header: {
          fontSize: 14,
          bold: true,
          margin: [0, 0, 0, 0],
          textAlign: "center",
        },
        tableExample: {
          margin: [0, 0, 0, 0],
          fontSize: 10,
          width: "90%",
        },
        tableOpacityExample: {
          margin: [0, 0, 0, 0],
          fillColor: "blue",
          fillOpacity: 0.3,
        },
        tableHeader: {
          bold: true,
          fontSize: 10,
          color: "black",
          alignment: "left",
        },
        tableBody: {
          bold: false,
          fontSize: 10,
          text: "capitalize",
          alignment: "left",
        },
      },
    };

    pdfMake.createPdf(docDefinition).download("keyword Data.pdf");
  };
  return (
    <Button type="primary" onClick={() => downloadSummaryPdf()}>
      PDF download
    </Button>
  );
};

export default GeneratePdf;
