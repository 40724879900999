import React from "react";
import FormModal from "components/Modal/FormModal";
import PetMainCategoryForm from "./PetMainCategoryForm";
import { intlPlaceholder } from "util/intlPlaceholder";
import { useSelector } from "react-redux";
import { Form } from "antd";
const AddPetMainCategory = () => {
  const [form] = Form.useForm();
  const { ADD_CATEGORY_MODAL } = useSelector((state) => state.CurdR);
  return (
    <FormModal
      addButtonName={intlPlaceholder("petMainCategory.addCategory")}
      modalTitle={intlPlaceholder("petMainCategory.addCategoryTitle")}
      addApiName="addCategory"
      ModalName="ADD_CATEGORY_MODAL"
      visible={ADD_CATEGORY_MODAL}
      width={500}
      recordName={"petMainCategoryList"}
      form={form}
    >
      <PetMainCategoryForm />
    </FormModal>
  );
};

export default AddPetMainCategory;
