import React from 'react'
import { Button, Modal, Form } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { DataRequestAction } from 'redux/actions/CommonHttp'

const CollectionCreateForm = ({
  visible,
  onCreate,
  onCancel,
  modalTitle,
  submitButtonText,
  cancelButtonText,
  children,

  width,
  className,
  showFooter = true,
}) => {
  const [form] = Form.useForm()
  const CrudProps = useSelector((state) => state.CurdR)
  const { Loader, initialValues } = CrudProps
  if (!visible) {
    form.resetFields()
  }
  if (initialValues) {
    form.setFieldsValue(initialValues)
  }

  return (
    <Modal
      visible={visible}
      title={modalTitle}
      width={width}
      form={form}
      bodyStyle={{
        paddingLeft: 0,
        paddingRight: 0,
      }}
      okText={submitButtonText}
      footer={false}
      cancelText={cancelButtonText}
      className={className}
      onCancel={() => {
        form.resetFields()
        onCancel()
      }}
      okButtonProps={{ disabled: showFooter }}
      confirmLoading={Loader}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onCreate(values)
          })
          .catch((info) => {})
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={initialValues}
      >
        {children}
      </Form>
    </Modal>
  )
}

const CollectionsPage = ({
  children,
  modalTitle,
  basicButtonTitle,
  submitButtonText = 'Update',
  cancelButtonText = 'Cancel',
  apiMethod = 'PUT',
  editApiRequest = 'EditRecord',
  visible,
  ModalType = 'Hide_Modal',
  editApiMethod = 'PUT',
  editApiName,
  width,
  className,

  apiLoader = 'StartSpinner',
  buttonStyleClass,
  isShowButton = true,
  extraFieldName,
  extraFieldValue,
  secondExtraFieldName,
  secondExtraFieldValue,
  ModalName,
  recordName,
}) => {
  const dispatch = useDispatch()
  const CrudProps = useSelector((state) => state.CurdR)
  const { Loader, initialValues } = CrudProps
  const onCreate = (values) => {
    if (values.email) {
      values.email = values.email.toLowerCase()
    }
    if (extraFieldName) {
      values[extraFieldName] = extraFieldValue
    }
    if (secondExtraFieldName) {
      values[secondExtraFieldName] = secondExtraFieldValue
    }
    if (initialValues) {
      values._id = initialValues._id
      values.inspectorId = initialValues.inspectorId
      dispatch(
        DataRequestAction(
          editApiMethod,
          editApiName,
          editApiRequest,
          values,
          apiLoader,
          ModalName,
          recordName
        )
      )
    }
  }

  return (
    <div>
      {isShowButton && (
        <Button
          type="primary"
          className={`gx-pt-0 ${buttonStyleClass}`}
          onClick={() => {
            dispatch({
              type: ModalType,
              payload: null,
            })
          }}
        >
          {basicButtonTitle}
        </Button>
      )}
      <CollectionCreateForm
        modalTitle={modalTitle}
        submitButtonText={submitButtonText}
        cancelButtonText={cancelButtonText}
        apiMethod={apiMethod}
        ModalType={ModalType}
        initialValues={initialValues}
        confirmLoading={Loader}
        apiLoaderName={apiLoader}
        visible={visible}
        width={width}
        className={className}
        onCreate={onCreate}
        onCancel={() => {
          dispatch({
            type: ModalType,
            payload: null,
            ModalName: ModalName,
            clear: true,
          })
        }}
      >
        {children}
      </CollectionCreateForm>
    </div>
  )
}

export default CollectionsPage
