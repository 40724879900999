import React from "react";
import Title from "components/Title";
import { useHistory } from "react-router";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Button } from "antd";
import { useSelector } from "react-redux";
import GeneratePdf from "./GeneratePdf";
const TableHeader = ({ state }) => {
  const history = useHistory();
  const congig = useSelector((state) => state.CurdR);
  const { DetailByKeywordList } = congig;
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <div
      className="gx-d-flex gx-align-items-center gx-justify-content-between gx-mb-3"
      style={{ flexWrap: "wrap" }}
    >
      <div className="gx-d-flex gx-align-items-center " style={{ gap: "6px" }}>
        <svg
          onClick={() => history.goBack()}
          style={{ marginRight: "10px", cursor: "pointer" }}
          fill="#000000"
          width="16px"
          height="16px"
          viewBox="0 0 1024 1024"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M222.927 580.115l301.354 328.512c24.354 28.708 20.825 71.724-7.883 96.078s-71.724 20.825-96.078-7.883L19.576 559.963a67.846 67.846 0 01-13.784-20.022 68.03 68.03 0 01-5.977-29.488l.001-.063a68.343 68.343 0 017.265-29.134 68.28 68.28 0 011.384-2.6 67.59 67.59 0 0110.102-13.687L429.966 21.113c25.592-27.611 68.721-29.247 96.331-3.656s29.247 68.721 3.656 96.331L224.088 443.784h730.46c37.647 0 68.166 30.519 68.166 68.166s-30.519 68.166-68.166 68.166H222.927z" />
        </svg>
        <Title title="search.title" />
        <h4 className="gx-font-sans-medium gx-mb-0 gx-text-capitalize">
          : {state && state.keyword}
        </h4>
      </div>
      <div
        className="gx-d-flex gx-align-items-center"
        style={{ flexWrap: "wrap" }}
      >
        <Button
          type="primary"
          onClick={(e) =>
            exportToCSV(
              DetailByKeywordList,
              `keyword ${state && state.keyword}`
            )
          }
        >
          Excel download
        </Button>
        <GeneratePdf
          keyword={state && state.keyword}
          data={DetailByKeywordList}
        />
      </div>
    </div>
  );
};

export default TableHeader;
