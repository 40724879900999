import React from "react";
import FormModal from "components/Modal/EditFormModal";
import ColorForm from "./ColorForm";
import { intlPlaceholder } from "util/intlPlaceholder";
import { useSelector } from "react-redux";
import { Form } from "antd";
const EditPetMainCategory = () => {
  const [form] = Form.useForm();
  const curdProps = useSelector((state) => state.CurdR);
  const { EDIT_Color_MODAL } = curdProps;

  return (
    <div>
      <FormModal
        modalTitle={intlPlaceholder("color.editPageTittle")}
        editApiName="updateColor"
        ModalName="EDIT_Color_MODAL"
        visible={EDIT_Color_MODAL}
        isShowButton={false}
        width={500}
        recordName={"colorList"}
        form={form}
      >
        <ColorForm />
      </FormModal>
    </div>
  );
};

export default EditPetMainCategory;
