import React from "react";
import FormModal from "components/Modal/EditFormModal";
import GenderForm from "./GenderForm";
import { intlPlaceholder } from "util/intlPlaceholder";
import { useSelector } from "react-redux";
import { Form } from "antd";
const EditGender = () => {
  const [form] = Form.useForm();
  const curdProps = useSelector((state) => state.CurdR);
  const { EDIT_Gender_MODAL } = curdProps;

  return (
    <div>
      <FormModal
        modalTitle={intlPlaceholder("gender.editPageTittle")}
        editApiName="updateGender"
        ModalName="EDIT_Gender_MODAL"
        visible={EDIT_Gender_MODAL}
        isShowButton={false}
        width={500}
        recordName={"genderList"}
        form={form}
      >
        <GenderForm />
      </FormModal>
    </div>
  );
};

export default EditGender;
