import React from "react";
import Layout from "components/Layout";
import Widget from "components/Widget";
import NewsList from "components/Table/List";
import Action from "components/Table/Actions";
import TableHeader from "./components/TableHeader";
import ShowModal from "components/Modal/ShowModal";
import DeleteConfirm from "components/Modal/DeleteConfirm";
import { intlPlaceholder } from "util/intlPlaceholder";
import EditNews from "./components/EditNews";
import ViewForm from "./components/ViewNews";
import moment from "moment/moment";

const Index = () => {
  const renderActions = (record) => {
    return (
      <div className="gx-d-flex gx-flex-column">
        <ShowModal
          modalName="EDIT_News_MODAL"
          buttonName="Edit"
          record={record}
        />

        <DeleteConfirm
          type="deleteNews"
          apiRequest="DeleteRecord"
          recordName="newsList"
          title="Are you sure you want to delete this news"
          content="You can't undo this action"
          selectedItem={record}
          Icon="Delete"
        />
      </div>
    );
  };

  const columns = [
    {
      title: intlPlaceholder("news.id"),
      render: (record) => <span>{record.newsId}</span>,
      key: "newsId",
      width: "100px",
    },
    {
      title: intlPlaceholder("news.title"),
      render: (record) => (
        <span className="gx-text-capitalize">{record.title}</span>
      ),
      key: "fullName",
      width: "150px",
    },

    {
      title: "Description",
      render: (record) => <span>{record.shortDescription}</span>,
      key: "noOfSubCategory",
      width: "200px",
    },

    {
      title: intlPlaceholder("View Details"),
      render: (record) => (
        <ShowModal
          modalName="View_Detail"
          buttonName="View"
          className="gx-link"
          record={record}
        />
      ),
      key: "createdBy",
      width: "150px",
    },
    {
      title: intlPlaceholder("news.attach"),
      render: (record) => (
        <span>
          {record && record.fileUrl && record.fileUrl.length > 0
            ? record.fileUrl.length
            : 0}
        </span>
      ),
      key: "attach",
      width: "150px",
    },
    {
      title: intlPlaceholder("adoptPet.createdBy"),
      render: (record) => (
        <span>{moment(record.createdAt).format("MMM-DD-YYYY")}</span>
      ),
      key: "createdBy",
    },
    {
      title: "",
      render: (record) => <Action>{renderActions(record)}</Action>,
      key: "action",
      width: "50px",
    },
  ];
  return (
    <Layout>
      <Widget>
        <TableHeader />
        <NewsList
          apiName="getNews"
          recordName="newsList"
          columns={columns}
          scroll={true}
          pagination={true}
        />
      </Widget>

      <EditNews />
      <ViewForm />
    </Layout>
  );
};

export default Index;
