import React, { useState } from "react";
import { Form, Input, Row, Col, Button, message } from "antd";
import { DataRequestAction } from "redux/actions/CommonHttp";
import { useDispatch, useSelector } from "react-redux";
import IntlMessages from "util/IntlMessages";
import AvatarUploader from "components/AvatarUploader";
import { intlPlaceholder } from "util/intlPlaceholder";
import SimpleModal from "components/Modal/SimpleModal";
import { Typography } from "antd";
import PhoneInput from "react-phone-input-2";

const { Title } = Typography;
const EditSetting = ({ setPhotoState, photoList }) => {
  const authProps = useSelector((state) => state.auth);
  const { profileLoader, profileEditeSuccess, settingsData } = authProps;
  const curdProps = useSelector((state) => state.CurdR);
  const [phoneNumber, setPhoneNumber] = useState();
  const [form] = Form.useForm();
  const { EDIT_PROFILE_MODAL } = curdProps;

  const dispatch = useDispatch();
  const onFinish = (values) => {
    if (photoList && photoList.imageUrl !== "") {
      values.profileImageUrl = photoList.imageUrl;
    }
    dispatch(
      DataRequestAction(
        "PUT",
        "updateProfile",
        "updateProfile",
        values,
        "profileSpinner",
        "EDIT_PROFILE_MODAL",
        "settingsData"
      )
    );
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    userInfo.profileImageUrl = photoList.imageUrl;
    userInfo.fullName = values.fullName;
    localStorage.setItem("userInfo", JSON.stringify(userInfo));
  };
  if (profileEditeSuccess) {
    message.success("Edit Profile  successfully");
    dispatch({
      type: "reset_profilesettings",
    });
  }

  const renderCloseHandler = () => {
    dispatch({
      type: "Hide_Modal",
      payload: null,
      ModalName: "EDIT_PROFILE_MODAL",
    });
  };

  return (
    <SimpleModal
      title={
        <Title level={5} className="gx-mb-0">
          {<IntlMessages id="setting.modalTitle" />}
        </Title>
      }
      modalFor="EDIT_PROFILE_MODAL"
      visible={EDIT_PROFILE_MODAL}
      width={650}
    >
      <Form
        layout="vertical"
        name="reassignMo"
        onFinish={onFinish}
        form={form}
        initialValues={settingsData && settingsData}
      >
        <Row className="gx-flex-row">
          <Col xl={12} lg={12} md={12} sm={24} xs={24} className="gx-px-0">
            <Form.Item
              name="fullName"
              label={intlPlaceholder("setting.clientName")}
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="setting.clientName_message" />,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="email"
              label={intlPlaceholder("setting.email")}
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="setting.email_message" />,
                },
              ]}
            >
              <Input disabled={true} readOnly />
            </Form.Item>
            <Form.Item
              name="phoneNumber"
              label={intlPlaceholder("setting.phone")}
              rules={[
                {
                  required: true,
                  message: <IntlMessages id="setting.phone_message" />,
                },
              ]}
            >
              <PhoneInput
                inputStyle={{
                  width: "100%",
                }}
                country={"sg"}
                prefix={'+'}
                onlyCountries={["sg"]}
                value={phoneNumber}
                onChange={(phone) => setPhoneNumber({ phone })}
              />
            </Form.Item>
          </Col>

          <Col
            xl={12}
            lg={12}
            md={12}
            sm={24}
            xs={24}
            className="gx-pr-0 gx-d-flex gx-align-items-center gx-justify-content-center"
          >
            <Form.Item name="photo">
              <AvatarUploader
                setPhotoState={setPhotoState}
                photoList={photoList}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gx-px-0">
            <div className="gx-flex gx-flex-row  gx-justify-content-end ">
              <Form.Item className="gx-mb-0">
                <div className="gx-d-flex">
                  <Button
                    style={{ backgroundColor: "#F6F6F6" }}
                    className="gx-pt-0 gx-mb-0"
                    htmlType="button"
                    onClick={() => renderCloseHandler()}
                  >
                    <IntlMessages id="setting.cancel" />
                  </Button>

                  <Button
                    type="primary"
                    className="gx-pt-0 gx-mb-0"
                    htmlType="submit"
                    loading={profileLoader}
                    disabled={profileLoader}
                  >
                    <IntlMessages id="setting.update" />
                  </Button>
                </div>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>
    </SimpleModal>
  );
};

export default EditSetting;
