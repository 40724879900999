import React from "react";
import Title from "components/Title";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Button } from "antd";
import { useSelector } from "react-redux";
import GeneratePdf from "./GeneratePdf";

const ShowViewDetailHeader = ({ state }) => {
  const congig = useSelector((state) => state.CurdR);
  const { getPostCountUserDetailsList } = congig;
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  return (
    <div
      className="gx-d-flex gx-align-items-center gx-justify-content-between"
      style={{ flexWrap: "wrap" }}
    >
      <Title
        title={`${state && state.title} - (${state && state.postType})`}
        className="gx-pb-3 gx-text-capitalize"
      />
      <div className="gx-d-flex gx-align-items-center" style={{ gap: "10px" }}>
        <Button
          type="primary"
          onClick={(e) =>
            exportToCSV(
              getPostCountUserDetailsList,
              `Post - ${state && state.title}`
            )
          }
        >
          Excel download
        </Button>
        <GeneratePdf
          postTitle={state && state.title}
          postType={state && state.postType}
          data={getPostCountUserDetailsList}
        />
      </div>
    </div>
  );
};

export default ShowViewDetailHeader;
