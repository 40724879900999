/* eslint-disable use-isnan */
import { CloudUploadOutlined, DeleteOutlined } from "@ant-design/icons";
import { message, Spin, Tag, Upload } from "antd";
import React, { useState } from "react";
const { Dragger } = Upload;

const App = ({ updateAssigned, myAssigned }) => {
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");
  const props = {
    name: "file",
    multiple: true,
    action: `${process.env.REACT_APP_ROOT_URL}/uploadProfileImage`,
    method: "POST",
    showRemoveIcon: false,
    showUploadList: false,

    headers: {
      Authorization: token,
    },
    onChange(info) {
      const { status } = info.file;
      if (status === "uploading") {
        setLoading(true);
      }
      if (status !== "uploading") {
        setLoading(false);
      }
      if (status === "done") {
        if (info.file && info.file.response && info.file.response.url) {
          updateAssigned((arr) => [...arr, info.file.response.url.location]);
        }
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
        setLoading(false);
      }
    },

    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const onRemoveImageFormList = (index) => {
    updateAssigned([
      ...myAssigned.slice(0, index),
      ...myAssigned.slice(index + 1, myAssigned.length),
    ]);
  };

  return (
    <>
      {/* {loading && (
        <Spin tip="Loading...">
          <Dragger {...props} disabled={loading}>
            <div style={{ height: "152px" }}></div>
          </Dragger>
        </Spin>
      )} */}

      <Dragger {...props}>
        {loading && (
          <div className='gx-d-flex gx-align-items-center gx-justify-content-center' style={{ height: "152px" }}>
            <Spin tip="Loading..." />
          </div>
        )}
        {!loading && (
          <div className="gx-d-flex gx-justify-content-center gx-align-items-center gx-flex-column">
            <CloudUploadOutlined
              style={{ fontSize: "40px", color: "#32979B" }}
            />
            <div className="gx-d-flex gx-flex-column" style={{ gap: "10px" }}>
              <span
                className="gx-text-dark gx-font-sans-bold"
                style={{ fontSize: "20px" }}
              >
                Drag & Drop files here
              </span>
              <span
                className="gx-text-dark gx-font-sans-bold"
                style={{ fontSize: "16px" }}
              >
                or
              </span>
              <Tag
                style={{ fontSize: "20px", width: "200px" }}
                className="gx-p-2"
                color="cyan"
              >
                Browse Files
              </Tag>
            </div>
          </div>
        )}
      </Dragger>

      {myAssigned &&
        myAssigned.map((item, index) => {
          return (
            <div
              className="gx-mt-2 gx-p-2 gx-d-flex gx-align-items-center gx-justify-content-between"
              style={{ border: "1px dashed #D9D9D9", borderRadius: "5px" }}
            >
              <div>
                <img
                  style={{ borderRadius: "5px" }}
                  src={item}
                  alt=""
                  width="30px"
                  height="30px"
                />

                <span className="gx-ml-2">Attach File # {index + 1}</span>
              </div>
              <span
                onClick={() => {
                  onRemoveImageFormList(index);
                }}
                className="gx-text-red"
              >
                <DeleteOutlined />
              </span>
            </div>
          );
        })}
    </>
  );
};

export default App;
