import React from "react";
import FormModal from "components/Modal/FormModal";
import GenderForm from "./GenderForm";
import { intlPlaceholder } from "util/intlPlaceholder";
import { useSelector } from "react-redux";
import { Form } from "antd";
const AddGender = () => {
  const [form] = Form.useForm();
  const { ADD_Gender_MODAL } = useSelector((state) => state.CurdR);
  return (
    <FormModal
      addButtonName={intlPlaceholder("gender.addGender")}
      modalTitle={intlPlaceholder("gender.genderTitle")}
      addApiName="addGender"
      ModalName="ADD_Gender_MODAL"
      visible={ADD_Gender_MODAL}
      width={500}
      recordName={"genderList"}
      form={form}
    >
      <GenderForm />
    </FormModal>
  );
};

export default AddGender;
