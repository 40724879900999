import React from "react";
import Layout from "components/Layout";
import Widget from "components/Widget";
import PetMainCategoryList from "components/Table/List";
import EditPetMainCategory from "./components/EditPetMainCategory";
import Action from "components/Table/Actions";
import TableHeader from "./components/TableHeader";
import ShowModal from "components/Modal/ShowModal";
import DeleteConfirm from "components/Modal/DeleteConfirm";
import { intlPlaceholder } from "util/intlPlaceholder";
import moment from "moment";
const Index = () => {
  const renderActions = (record) => {
    return (
      <div className="gx-d-flex gx-flex-column">
        <ShowModal
          modalName="EDIT_PetMainCategory_MODAL"
          buttonName="Edit"
          record={record}
        />

        <DeleteConfirm
          type="deleteCategory"
          apiRequest="DeleteRecord"
          recordName="petMainCategoryList"
          title="Are you sure you want to delete this Category"
          content="You can't undo this action"
          selectedItem={record}
          Icon="Delete"
        />
      </div>
    );
  };

  const columns = [
    {
      title: intlPlaceholder("petMainCategory.id"),
      render: (record) => <span>{record.categoryId}</span>,
      key: "petMainCategoryId",
      width: "100px",
    },
    {
      title: intlPlaceholder("petMainCategory.fullName"),
      render: (record) => (
        <span className="gx-text-capitalize">{record.name}</span>
      ),
      key: "fullName",
      width: "150px",
    },

    // {
    //   title: intlPlaceholder("petMainCategory.noOfSubCategory"),
    //   render: (record) => <span>{record.noOfSubCategory}</span>,
    //   key: "phoneNumber",
    //   width: "200px",
    // },
    {
      title: intlPlaceholder("Total Post"),
      render: (record) => <span>{record.totalPost}</span>,
      key: "phoneNumber",
      width: "200px",
    },
    {
      title: intlPlaceholder("petMainCategory.createdBy"),
      render: (record) => (
        <span>{moment(record.createdAt).format("MMM-DD-YYYY")}</span>
      ),
      key: "createdBy",
    },
    {
      title: "",
      render: (record) => <Action>{renderActions(record)}</Action>,
      key: "action",
      width: "50px",
    },
  ];
  return (
    <Layout>
      <Widget>
        <TableHeader />
        <PetMainCategoryList
          apiName="getCategory"
          recordName="petMainCategoryList"
          columns={columns}
          scroll={true}
          pagination={true}
        />
      </Widget>
      <EditPetMainCategory />
    </Layout>
  );
};

export default Index;
