import React from "react";
import { intlPlaceholder } from "../../util/intlPlaceholder";

const Index = ({ title, className }) => {
  return (
    <h4 className={`gx-font-sans-medium gx-mb-0 ${className}`}>
      {intlPlaceholder(title)}
    </h4>
  );
};

export default Index;
