import { Avatar, Modal } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

const UsersModal = () => {
  const dispatch = useDispatch();
  const congig = useSelector((state) => state.CurdR);
  const { initialValues, Show_Users_Modals } = congig;
  console.log("==initialValues===", initialValues);
  return (
    <Modal
      open={Show_Users_Modals}
      onOk={() => {
        dispatch({
          type: "Hide_Modal",
          ModalName: "Show_Users_Modals",
        });
      }}
      onCancel={() =>
        dispatch({
          type: "Hide_Modal",
          ModalName: "Show_Users_Modals",
        })
      }
    >
      <div
        className="gx-d-flex gx-align-items-center"
        style={{ gap: "15px", flexWrap: "wrap" }}
      >
        {initialValues &&
          initialValues.userDetails &&
          initialValues.userDetails.length > 0 &&
          initialValues.userDetails.map((item) => {
            return (
              <div
                className="gx-d-flex gx-p-2"
                style={{ gap: "6px", border: "1px solid #CCCCCC",borderRadius:'10px' }}
              >
                <Avatar src={item.profileImageUrl} />
                <div className="gx-d-flex gx-flex-column">
                  <span>{item.fullName}</span>
                  <span>{item.email}</span>
                </div>
              </div>
            );
          })}
      </div>
    </Modal>
  );
};

export default UsersModal;
