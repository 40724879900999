import React from "react";
import { useDispatch } from "react-redux";
import IntlMessages from "util/IntlMessages";
const ShowModal = ({
  record,
  type = "Show_Modal",
  modalName,
  className,
  buttonName,
  children,
}) => {
  const dispatch = useDispatch();
  return (
    <span
      onClick={() =>
        dispatch({
          type: type,
          payload: record,
          ModalName: modalName,
        })
      }
      className={`gx-pointer ${className}`}
    >
      {buttonName && <IntlMessages id={`${buttonName}`} />}
      {children}
    </span>
  );
};

export default ShowModal;
