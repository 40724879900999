import { Col, Form, Input, Row } from "antd";
import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import AvatarUploader from "components/AvatarUploader";
const { TextArea } = Input;
const Forms = ({ formType, setPhotoState, photoList }) => {
  const [phoneNumber, setPhoneNumber] = useState();
  const types = formType === "edit" ? true : false;
  return (
    <>
      <Row className="gx-flex-row">
        <Col xl={14} lg={14} md={14} sm={24} xs={24}>
          <Form.Item
            name="username"
            label="Username"
            rules={[
              {
                required: true,
                message: "Username is required",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="fullName"
            label="Name"
            rules={[
              {
                required: true,
                message: "Name is required",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                message: "Name is required",
              },
              {
                type: "email",
                message: "Enter valid Email",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="phoneNumber"
            label="Phone"
            rules={[
              {
                required: true,
                message: "Phone is required",
              },
            ]}
          >
            <PhoneInput
              inputStyle={{
                width: "100%",
              }}
              country={"us"}
              value={phoneNumber}
              onChange={(phone) => setPhoneNumber({ phone })}
            />
          </Form.Item>
        </Col>
        <Col xl={10} lg={10} md={10} sm={24} xs={24} className="gx-pt-4">
          <AvatarUploader setPhotoState={setPhotoState} photoList={photoList} />
        </Col>
      </Row>

      {!types && (
        <Row className="gx-flex-row">
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                  message: "Password is required",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              name="confirmPassword"
              label="Confirm Password"
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                  message: "Confirm Password is required",
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject("Password not match");
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Form.Item name="description" label="Description">
            <TextArea rows={5} />
          </Form.Item>
        </Col>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Form.Item name="shortDescription" label="Short Description">
            <TextArea rows={3} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default Forms;
