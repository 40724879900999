const List = {
  superAdmin: [
    {
      title: "sidebarItem.dashboard",
      url: "/dashboard",
      icon: "dasbhoard",
      key: "dashboard",
    },
    {
      title: "sidebarItem.user",
      url: "/user",
      icon: "wall",
      key: "user",
    },
    {
      title: "sidebarItem.search",
      url: "/search",
      icon: "search",
      key: "search",
    },
    {
      title: "sidebarItem.Post",
      url: "",
      icon: "chat-new",
      permissionKey: "post",
      key: "post",
      subMenu: [
        {
          title: "sidebarItem.findPet",
          url: "/post/findPet",
          icon: "",
          key: "findPet",
        },
        {
          title: "sidebarItem.adoptPet",
          url: "/post/adoptPet",
          icon: "",
          key: "adoptPet",
        },
        {
          title: "sidebarItem.salePet",
          url: "/post/salePet",
          icon: "",
          key: "salePet",
        },
      ],
    },
    {
      title: "sidebarItem.PostSetting",
      url: "",
      icon: "setting",
      permissionKey: "PostSetting",
      key: "PostSetting",
      subMenu: [
        {
          title: "Age",
          url: "/PostSetting/age",
          icon: "",
          key: "age",
        },
        {
          title: "Colour",
          url: "/PostSetting/color",
          icon: "",
          key: "color",
        },
        {
          title: "Gender",
          url: "/PostSetting/gender",
          icon: "",
          key: "gender",
        },
        {
          title: "sidebarItem.petMainCategory",
          url: "/PostSetting/category",
          icon: "",
          key: "petMainCategory",
        },
      ],
    },
    {
      title: "sidebarItem.directMessage",
      url: "/directNiotification",
      icon: "chat",
      key: "directNiotification",
    },
    {
      title: "sidebarItem.news",
      url: "/news",
      icon: "copy",
      key: "news",
    },
    {
      title: "sidebarItem.broadcastMessage",
      url: "/broadcastMessage",
      icon: "alert",
      key: "notification",
    },
    {
      title: "sidebarItem.banner",
      url: "/banner",
      icon: "notification",
      key: "banner",
    },
    {
      title: "sidebarItem.setting",
      url: "/setting",
      icon: "setting",
      key: "setting",
    },
  ],
};

export default List;
