import React from "react";
import { useSelector } from "react-redux";
import ViewModal from "../../../components/Modal/ViewModal";
import NewsForm from "./NewsForm";
const ViewForm = () => {
  const curdProps = useSelector((state) => state.CurdR);
  const { View_Detail, initialValues } = curdProps;
  return (
    <ViewModal
      modalTitle="View Details"
      ModalName="View_Detail"
      visible={View_Detail}
      isShowButton={false}
      width={500}
    >
      <NewsForm initialValues={initialValues} formType="view" />
    </ViewModal>
  );
};

export default ViewForm;
