import React from "react";
import Layout from "components/Layout";
import Widget from "components/Widget";
import PetMainCategoryList from "components/Table/List";
import EditAge from "./components/EditAge";
import Action from "components/Table/Actions";
import TableHeader from "./components/TableHeader";
import ShowModal from "components/Modal/ShowModal";
import DeleteConfirm from "components/Modal/DeleteConfirm";
import { intlPlaceholder } from "util/intlPlaceholder";
import moment from "moment";
const Index = () => {
  const renderActions = (record) => {
    return (
      <div className="gx-d-flex gx-flex-column">
        <ShowModal
          modalName="EDIT_AGE_MODAL"
          buttonName="Edit"
          record={record}
        />

        <DeleteConfirm
          type="deleteAge"
          apiRequest="DeleteRecord"
          recordName="ageList"
          title="Are you sure you want to delete this record"
          content="You can't undo this action"
          selectedItem={record}
          Icon="Delete"
        />
      </div>
    );
  };

  const columns = [
    {
      title: intlPlaceholder("age.id"),
      render: (record) => <span>{record.ageId}</span>,
      key: "id",
      width: "100px",
    },
    {
      title: intlPlaceholder("age.enterAge"),
      render: (record) => (
        <span className="gx-text-capitalize">{record.age}</span>
      ),
      key: "fullName",
      width: "150px",
    },
    {
      title: intlPlaceholder("age.createdBy"),
      render: (record) => (
        <span>{moment(record.createdAt).format("MMM-DD-YYYY")}</span>
      ),
      key: "createdBy",
    },
    {
      title: "",
      render: (record) => <Action>{renderActions(record)}</Action>,
      key: "action",
      width: "50px",
    },
  ];
  return (
    <Layout>
      <Widget>
        <TableHeader />
        <PetMainCategoryList
          apiName="getAge"
          recordName="ageList"
          columns={columns}
          scroll={true}
          pagination={true}
        />
      </Widget>
      <EditAge />
    </Layout>
  );
};

export default Index;
