import React from "react";
import FormModal from "components/Modal/FormModal";
import SendMessageForm from "./SendMessageForm";
import { useSelector } from "react-redux";
import { Form } from "antd";
const AddNewMessage = () => {
  const [form] = Form.useForm();
  const { ADD_Messge_MODAL } = useSelector((state) => state.CurdR);

  return (
    <FormModal
      addButtonName="Send New"
      modalTitle="Send Notification"
      addApiName="sendDirectMessage"
      ModalName="ADD_Messge_MODAL"
      visible={ADD_Messge_MODAL}
      width={600}
      recordName={"getDirectMessageList"}
      form={form}
    >
      <SendMessageForm form={form} />
    </FormModal>
  );
};

export default AddNewMessage;
