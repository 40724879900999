import React, { useEffect, useState } from "react";
import FormModal from "components/Modal/FormModal";
import UserForm from "./UserForm";
import { useSelector } from "react-redux";
import { Form } from "antd";
const AddPetMainCategory = () => {
  const [form] = Form.useForm();
  let initialState = {
    loading: false,
    imageUrl: "",
  };
  const [photoList, setPhotoState] = useState(initialState);
  const { ADD_User_MODAL } = useSelector((state) => state.CurdR);
  useEffect(() => {
    if (ADD_User_MODAL) {
      setPhotoState({
        loading: false,
        imageUrl: "",
      });
    }
  }, [ADD_User_MODAL]);

  return (
    <FormModal
      addButtonName="Add User"
      modalTitle="Add New User"
      addApiName="addUser"
      ModalName="ADD_User_MODAL"
      visible={ADD_User_MODAL}
      width={600}
      recordName={"userList"}
      form={form}
      extraFieldName="profileImageUrl"
      extraFieldValue={photoList.imageUrl}
    >
      <UserForm setPhotoState={setPhotoState} photoList={photoList} />
    </FormModal>
  );
};

export default AddPetMainCategory;
