import { Button, Form, Input, Select } from "antd";
import React, { useEffect } from "react";
import { DataGetAction } from "../../redux/actions/CommonHttp";
import { useDispatch, useSelector } from "react-redux";

const PostFilters = ({ type, recordName }) => {
  const dispatch = useDispatch();
  const {
    categorySearchDataList,
    colorSearchDataList,
    ageSearchDataList,
    genderSearchDataList,
  } = useSelector((state) => state.CurdR);
  const getFiltersValues = () => {
    dispatch(
      DataGetAction(
        "getCategory",
        "FetchRecord",
        { query: "all" },
        "",
        "",
        "categorySearchDataList"
      )
    );
    dispatch(
      DataGetAction(
        "getColor",
        "FetchRecord",
        { query: "all" },
        "",
        "",
        "colorSearchDataList"
      )
    );
    dispatch(
      DataGetAction(
        "getAge",
        "FetchRecord",
        { query: "all" },
        "",
        "",
        "ageSearchDataList"
      )
    );
    dispatch(
      DataGetAction(
        "getGender",
        "FetchRecord",
        { query: "all" },
        "",
        "",
        "genderSearchDataList"
      )
    );
  };
  useEffect(getFiltersValues, []);
  const renderSelectData = (data, fieldKey) => {
    if (data && data.length > 0) {
      return data.map((item) => {
        return <Select.Option value={item._id}>{item[fieldKey]}</Select.Option>;
      });
    }
  };
  const onFinish = (values) => {
    dispatch(
      DataGetAction(
        "getPost",
        "FetchRecord",
        { ...values, type: type },
        "",
        "",
        recordName
      )
    );
  };
  return (
    <Form
      onFinish={onFinish}
      layout="vertical"
      className="gx-d-flex"
      style={{ gap: "10px" }}
    >
      <Form.Item name="name" className="gx-m-0">
        <Input placeholder="ID / Title / Breed" style={{ width: "150px" }} />
      </Form.Item>
      <Form.Item name="categoryId" className="gx-m-0">
        <Select
          allowClear={true}
          placeholder="Category"
          style={{ width: "100px" }}
        >
          <Select.Option value="all">All</Select.Option>
          {renderSelectData(categorySearchDataList, "name")}
        </Select>
      </Form.Item>
      <Form.Item name="ageId" className="gx-m-0">
        <Select allowClear={true} placeholder="Age" style={{ width: "100px" }}>
          <Select.Option value="all">All</Select.Option>
          {renderSelectData(ageSearchDataList, "age")}
        </Select>
      </Form.Item>
      <Form.Item name="colorId" className="gx-m-0">
        <Select
          allowClear={true}
          placeholder="Color"
          style={{ width: "100px" }}
        >
          <Select.Option value="all">All</Select.Option>
          {renderSelectData(colorSearchDataList, "colorName")}
        </Select>
      </Form.Item>
      <Form.Item name="genderId" className="gx-m-0">
        <Select
          allowClear={true}
          placeholder="Gender"
          style={{ width: "100px" }}
        >
          <Select.Option allowClear={true} value="all">
            All
          </Select.Option>
          {renderSelectData(genderSearchDataList, "genderType")}
        </Select>
      </Form.Item>
      <Form.Item name="status" className="gx-m-0">
        <Select
          allowClear={true}
          placeholder="Status"
          style={{ width: "100px" }}
        >
          <Select.Option value="all">All</Select.Option>
          <Select.Option value="active">Active</Select.Option>
          <Select.Option value="block">Block</Select.Option>
          <Select.Option value="pending">Pending</Select.Option>
          <Select.Option value="deleted">Deleted</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item className="gx-m-0">
        <Button htmlType="submit" type="primary">
          Search
        </Button>
      </Form.Item>
    </Form>
  );
};

export default PostFilters;
