import React from "react";
import Layout from "components/Layout";
import Widget from "components/Widget";
import UserList from "components/Table/List";
import TableHeader from "./components/TableHeader";
import { Avatar } from "antd";
import UsersModal from "./components/UsersModal";
import { useDispatch } from "react-redux";

const Index = () => {
  const dispatch = useDispatch();
  const renderUsers = (record) => {
    if (record && record.userDetails && record.userDetails.length > 0) {
      return record.userDetails.map((item, index) => {
        if (index <= 4) {
          return <Avatar src={item.profileImageUrl} />;
        }
      });
    }
  };
  const columns = [
    {
      title: "Id",
      render: (record) => <span>{record.incrementalId}</span>,
      key: "Id",
      width: "100px",
    },
    {
      title: "Title",
      render: (record) => (
        <span className="gx-text-capitalize">{record.title || "-----"}</span>
      ),
      key: "title",
      width: "150px",
    },
    {
      title: "Type",
      render: (record) => (
        <span className="gx-text-capitalize">{record.postType || "-----"}</span>
      ),
      key: "Type",
      width: "150px",
    },
    {
      title: "Post Id",
      render: (record) => (
        <span className="gx-text-capitalize">{record.postId || "---"}</span>
      ),
      key: "Post",
      width: "100px",
    },
    {
      title: "Notification Text",
      render: (record) => <span>{record.notificationText || "---"}</span>,
      key: "Notification",
    },
    {
      title: "Users",
      render: (record) => (
        <span className="gx-d-flex gx-align-items-center">
          <Avatar.Group>{renderUsers(record)}</Avatar.Group>{" "}
          <span
            onClick={() =>
              dispatch({
                type: "Show_Modal",
                ModalName: "Show_Users_Modals",
                payload: record,
              })
            }
            style={{ cursor: "pointer" }}
            className="gx-text-primary gx-ml-2"
          >
            View
          </span>
        </span>
      ),
      key: "Users",
      width: "200px",
    },
  ];

  return (
    <Layout>
      <Widget>
        <TableHeader />
        <UserList
          apiName="getDirectMessageList"
          recordName="getDirectMessageList"
          columns={columns}
          scroll={true}
          pagination={true}
        />
      </Widget>
      <UsersModal />
    </Layout>
  );
};

export default Index;
