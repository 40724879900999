import React from "react";
import { Button, Col, Empty, Row } from "antd";
import Widget from "components/Widget/index";
import { useHistory } from "react-router-dom";

const SendMoney = ({ title, data, route }) => {
  const history = useHistory();
  return (
    <Widget>
      <div className="gx-d-flex gx-align-items-center gx-justify-content-between gx-mb-3">
        <h2 className="h4 gx-text-capitalize gx-mb-0 gx-font-sans-medium">
          {title}
        </h2>{" "}
        <p
          style={{ color: "#00abc8" }}
          className="gx-mb-0 gx-pointer gx-d-none gx-d-sm-block"
          onClick={() => history.push(`/${route}`)}
        >
          View All
        </p>
      </div>

      <div
        className="gx-table-responsive"
        style={{ height: "280px", overflowY: "auto", overflowX: "hidden" }}
      >
        {data && !data.length > 0 && (
          <div className="gx-d-flex gx-h-100 gx-align-items-center gx-justify-content-center">
            <Empty />
          </div>
        )}
        {data && data.length > 0 && (
          <>
            {data &&
              data.map((item) => (
                <>
                  <Row className="gx-w-100 gx-m-0">
                    <Col
                      xl={18}
                      lg={18}
                      md={18}
                      sm={24}
                      xs={24}
                      className="gx-d-flex gx-flex-column gx-pl-0"
                    >
                      <span className="gx-font-sans-medium">
                        {item.name && item.name.length > 40
                          ? item.name.slice(0, 40) + "..."
                          : item.name}
                      </span>
                      <span>
                        {item.description && item.description.length > 40
                          ? item.description.slice(0, 40) + "..."
                          : item.description}
                      </span>
                    </Col>
                    <Col
                      xl={6}
                      lg={6}
                      md={6}
                      className="gx-d-md-flex gx-d-none gx-px-0 gx-align-items-center gx-justify-content-end"
                    >
                      <Button
                        onClick={() => history.push(`/${route}`)}
                        className="gx-mb-0   gradient-button"
                        type="primary"
                      >
                        More Info
                      </Button>
                    </Col>
                  </Row>
                  <hr className="gx-my-3" />
                </>
              ))}
          </>
        )}
      </div>
    </Widget>
  );
};

export default SendMoney;
