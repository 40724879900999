import React from "react";
import { intlPlaceholder } from "../../../util/intlPlaceholder";
import Widget from "../../Widget";
import AuthLeftSide from "../AuthLeftSide/AuthLeftSide";
import { DataRequestAction } from "redux/actions/CommonHttp";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Form, Input, Button, message } from "antd";

const { Title } = Typography;
const Index = () => {
  const history = useHistory();
  const authProps = useSelector((state) => state.auth);
  const { ForSuccess, ShowForgotLoader, ForError } = authProps;
  const dispatch = useDispatch();
  const onFinish = (values) => {
    values.email = values.email.toLowerCase();
    dispatch(
      DataRequestAction(
        "POST",
        "forgetPassword",
        "forgetPassword",
        values,
        "forgot",
        "",
        ""
      )
    );
  };

  if (ForSuccess) {
    history.push("/login");
    message.success("Email sent in your inbox");
    dispatch({
      type: "update_auth",
    });
  }
  if (ForError) {
    message.error("Some error occur try later");
    dispatch({
      type: "update_auth",
    });
  }

  return (
    <AuthLeftSide>
      <Widget className="login-form  gx-mr-md-4 gx-ml-md-0 gx-ml-3">
        <div
          className="gx-h-100 gx-d-flex gx-flex-column gx-align-items-center gx-justify-content-center"
          style={{ gap: "8%" }}
        >
          <div className="gx-text-center">
            <Title
              className="gx-font-sans-bold gx-text-black gx-mb-0"
              level={2}
            >
              {intlPlaceholder("forget.pageTitle")}
            </Title>
            <Title className="gx-font-sans-regular gx-mt-0" level={5}>
              {intlPlaceholder("Forgot.content")}
            </Title>
          </div>
          <Form
            layout="vertical"
            className="gx-py-3 gx-d-flex gx-flex-column  login-form-inputs"
            onFinish={onFinish}
          >
            <Form.Item
              name="email"
              label={intlPlaceholder("forget.inputLabel")}
              rules={[
                {
                  required: true,
                  message: intlPlaceholder("forget.email_message"),
                },
                {
                  type: "email",
                  message: "Enter Valid Email",
                },
              ]}
            >
              <Input
                className="gx-text-lowercase"
                placeholder={intlPlaceholder("forget.email_placeholder")}
              />
            </Form.Item>

            <Button
              htmlType="submit"
              className="gx-w-100 gx-mt-4 gradient-button"
              loading={ShowForgotLoader}
              type="primary"
              disabled={ShowForgotLoader}
            >
              {intlPlaceholder("forget.sentBtn")}
            </Button>
            <div className="gx-d-flex gx-mb-3  gx-justify-content-center">
              <span
                className="gx-link gx-pointer gx-font-sans-bold gx-font-sans-regular gx-d-flex gx-flex-end gx-pointer gx-mt-2 gx-pt-1 "
                style={{ color: "#4C5755" }}
              >
                Back to
                <span
                  className="gx-link gx-ml-1"
                  onClick={() => history.push("/login")}
                >
                  login ?
                </span>
              </span>
            </div>
          </Form>
        </div>
      </Widget>
    </AuthLeftSide>
  );
};

export default Index;
