import React from "react";
import SimpleModal from "../../../components/Modal/SimpleModal";
import { useSelector } from "react-redux";

const ViewLinksModal = () => {
  const { View_Links_MODAL, initialValues } = useSelector(
    (state) => state.CurdR
  );

  return (
    <SimpleModal
      modalFor="View_Links_MODAL"
      visible={View_Links_MODAL}
      title="Post Links"
      width="300px"
    >
      <div className="gx-d-flex gx-align-items-center gx-justify-content-between">
        <p className="gx-font-sans-bold">Link Name</p>
        <p className="gx-font-sans-bold">Clicks</p>
      </div>
      {initialValues &&
        initialValues.map((item) => (
          <div className="gx-d-flex gx-align-items-center gx-justify-content-between">
            <p className="gx-font-sans-regular gx-text-capitalize">
              {item.name && item.name.length > 20
                ? item.name.slice(0, 20) + "..."
                : item.name}
            </p>
            <p className="gx-font-sans-bold gx-text-capitalize">
              {item.count || 0}
            </p>
          </div>
        ))}
    </SimpleModal>
  );
};

export default ViewLinksModal;
