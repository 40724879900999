import React, { useEffect, useState } from "react";
import FormModal from "components/Modal/EditFormModal";
import BannerFrom from "./BannerFrom";
import { useSelector } from "react-redux";
import { Form } from "antd";

const EditBanner = () => {
  const [form] = Form.useForm();
  let initialState = {
    loading: false,
    imageUrl: "",
  };
  const [photoList, setPhotoState] = useState(initialState);
  const curdProps = useSelector((state) => state.CurdR);
  const { EDIT_BANNER_MODAL, initialValues } = curdProps;
  useEffect(() => {
    if (initialValues) {
      setPhotoState({
        loading: false,
        imageUrl: initialValues.profileImageUrl,
      });
    }
  }, [initialValues]);
  return (
    <div>
      <FormModal
        modalTitle="Edit Banner"
        editApiName="updateBanner"
        ModalName="EDIT_BANNER_MODAL"
        visible={EDIT_BANNER_MODAL}
        isShowButton={false}
        width={600}
        recordName={"bannerList"}
        form={form}
        extraFieldName="profileImageUrl"
        screenName={"banner"}
        extraFieldValue={photoList.imageUrl}
      >
        <BannerFrom
          photoList={photoList}
          initialValues={initialValues}
          setPhotoState={setPhotoState}
        />
      </FormModal>
    </div>
  );
};

export default EditBanner;
